import React from "react";
import { AppState } from "react-native";
import { Observer, useObserver } from "mobx-react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
// require("date-time-format-timezone");

import {
  Box,
  HStack,
  Icon,
  Text,
  VStack,
  Button,
  Avatar,
  Image,
  ScrollView,
  Pressable,
  Hidden,
  Spinner,
  Heading,
  Center,
  IconButton,
  ArrowBackIcon,
  FormControl,
  Select,
  Modal,
  Input,
  useToast,
  Divider,
} from "native-base";
import {
  Entypo,
  Feather,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
  SimpleLineIcons,
  CheckIcon,
} from "@expo/vector-icons";

import { StoreContext } from "../../utilities/store";
import DashboardLayout from "../../layouts/DashboardLayout";
import { fetchAPIJSON } from "../../utilities/network";
// import DatePicker from "react-native-date-picker";
import WebDatePicker from "../../components/WebDatePicker";

function MobileFooter(props) {
  const store = React.useContext(StoreContext);
  return (
    <Hidden from="md">
      <HStack
        justifyContent="space-between"
        safeAreaBottom
        h="20"
        width="100%"
        position="absolute"
        left="0"
        right="0"
        bottom="0"
        overflow="hidden"
        alignSelf="center"
        borderTopLeftRadius="24"
        borderTopRightRadius="24"
        _light={{ backgroundColor: "white" }}
        _dark={{ backgroundColor: "coolGray.800" }}
      >
        <Button
          variant="ghost"
          flex={1}
          colorScheme="coolGray"
          _stack={{
            flexDirection: "column",
          }}
          startIcon={<Icon as={MaterialIcons} name="message" size="5" />}
          onPress={() => {
            props.nav.navigate("HomeScreen");
          }}
        ></Button>
        {store.accessOnCall && (
          <Button
            variant="ghost"
            flex={1}
            colorScheme="coolGray"
            _stack={{
              flexDirection: "column",
            }}
            startIcon={
              <Icon
                as={MaterialIcons}
                name="perm-contact-calendar"
                size="6"
                color="primary.500"
              />
            }
            onPress={() => {}}
          ></Button>
        )}
        {store.accessGhostCall && (
          <Button
            variant="ghost"
            flex={1}
            colorScheme="coolGray"
            _stack={{
              flexDirection: "column",
            }}
            startIcon={
              <Icon as={MaterialIcons} name="phone-in-talk" size="5" />
            }
            onPress={() => {
              props.nav.navigate("GhostCallScreen");
            }}
          ></Button>
        )}
        {store.accessCallRecordings && (
          <Button
            variant="ghost"
            flex={1}
            colorScheme="coolGray"
            _stack={{
              flexDirection: "column",
            }}
            startIcon={
              <Icon as={MaterialIcons} name="multitrack-audio" size="5" />
            }
            onPress={() => {
              props.nav.navigate("CallRecordingsScreen");
            }}
          ></Button>
        )}
        {store.accessDatatables && (
          <Button
            variant="ghost"
            flex={1}
            colorScheme="coolGray"
            _stack={{
              flexDirection: "column",
            }}
            startIcon={<Icon as={MaterialIcons} name="view-list" size="5" />}
            onPress={() => {
              props.nav.navigate("DataTablesScreen");
            }}
          ></Button>
        )}
        <Button
          variant="ghost"
          flex={1}
          colorScheme="coolGray"
          _stack={{
            flexDirection: "column",
          }}
          startIcon={<Icon as={MaterialIcons} name="exit-to-app" size="5" />}
          onPress={() => {
            store.signOut();
            props.nav.navigate("Splash");
          }}
        ></Button>
      </HStack>
    </Hidden>
  );
}

const OnCallAccountCard = ({
  item,
  onCallEvents,
  openModal,
  openCreateModal,
}) => {
  return (
    <VStack
      px={{ base: 4, md: 6 }}
      pt={{ base: 4, md: 6 }}
      pb={{ base: 24, md: 6 }}
      mt={3}
    >
      <HStack justifyContent={"flex-end"}>
        <Pressable
          mr="3"
          onPress={() => {
            openCreateModal(item.acc_id, item.timezone);
          }}
        >
          <Icon as={MaterialIcons} name="add" size="6" color={"green.600"} />
        </Pressable>
      </HStack>
      <VStack borderRadius="lg" mt={{ base: "0", md: "1" }}>
        <Text
          _dark={{ color: "coolGray.50" }}
          _light={{ color: "coolGray.800" }}
          fontSize="xl"
          fontWeight="bold"
        >
          {item.acc_name} On Call
        </Text>

        {onCallEvents?.map(
          ({
            event_id,
            acc_id,
            event_timezone,
            contact_first_name,
            contact_last_name,
            reason_id,
            reason_name,
            start_date_time,
            end_date_time,
            contact_id,
            event_group_id,
            group_acc_ids,
          }) => {
            if (acc_id == item.acc_id) {
              return (
                <Pressable
                  onPress={() => {
                    openModal(
                      acc_id,
                      reason_id,
                      contact_id,
                      event_id,
                      start_date_time,
                      end_date_time,
                      event_group_id != null,
                      event_group_id,
                      event_timezone,
                      group_acc_ids
                    );
                  }}
                  key={event_id}
                >
                  <VStack
                    mt={{ base: "4", md: "1" }}
                    key={event_id}
                    px={{ base: 4, md: 6 }}
                    py={3}
                    borderRadius="lg"
                    borderColor={"warmGray.100"}
                    borderWidth={"1"}
                    bg={"white"}
                    shadow={"2"}
                  >
                    <HStack mb={2}>
                      <Text
                        fontSize="lg"
                        fontWeight={"semibold"}
                        color={"primary.700"}
                      >
                        {reason_name} - {contact_first_name} {contact_last_name}
                      </Text>
                    </HStack>

                    <Text>
                      {dayjs(start_date_time)
                        .tz(event_timezone)
                        .format("MM-DD-YY hh:mm A z")}
                    </Text>
                    <Text>
                      {dayjs(end_date_time)
                        .tz(event_timezone)
                        .format("MM-DD-YY hh:mm A z")}
                    </Text>
                  </VStack>
                </Pressable>
              );
            }
          }
        )}
      </VStack>
    </VStack>
  );
};

const NewReasonModal = ({
  modalAccID,
  nav,
  closeModal,
  openPrevModal,
  setModalContactID,
  setModalReasonID,
  modalContactID,
  checkOnCall,
}) => {
  const [newReasonName, setNewReasonName] = React.useState("");
  const store = React.useContext(StoreContext);

  const handleCreateNewOnCallReason = () => {
    let prevContact = modalContactID;
    fetchAPIJSON("api/v1/blaze/createOnCallReason", store, nav, {
      acc_id: modalAccID,
      reason_name: newReasonName,
    })
      .then((res) => {
        checkOnCall().then(() => {
          setNewReasonName("");
          setModalReasonID(res?.data?.reason_id);
          setModalContactID(prevContact);
          // setNewReasonModalVisible(false);
          // setModalVisible(true);
          closeModal();
          openPrevModal();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      isOpen={true}
      onClose={() => {
        // setNewReasonModalVisible(false);
        // setModalVisible(true);
        closeModal();
        openPrevModal();
      }}
      avoidKeyboard
      size="lg"
    >
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header>{"Create New On Call Reason"}</Modal.Header>
        <Modal.Body>
          {/* {"Select a new on call contact for " + modalReasonID} */}
          <VStack>
            <Input
              size="md"
              placeholder="New Reason"
              value={newReasonName}
              onChangeText={(e) => {
                setNewReasonName(e);
              }}
            />
          </VStack>
        </Modal.Body>
        <Modal.Footer>
          <Button
            flex="1"
            onPress={() => {
              handleCreateNewOnCallReason();
            }}
          >
            Save New Reason
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default function OnCallScreen(props) {
  const store = React.useContext(StoreContext);
  const appState = React.useRef(AppState.currentState);
  const toast = useToast();

  const [onCallEvents, setOnCallEvents] = React.useState([]);
  const [contacts, setContacts] = React.useState([]);
  const [accounts, setAccounts] = React.useState([]);
  const [reasons, setReasons] = React.useState([]);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [modalAccID, setModalAccID] = React.useState("");
  const [modalEventID, setModalEventID] = React.useState("");
  const [modalContactID, setModalContactID] = React.useState("");
  const [modalReasonID, setModalReasonID] = React.useState("");
  const [modalIsGroupEvent, setModalIsGroupEvent] = React.useState(false);
  const [modalGroupAccIds, setModalGroupAccIds] = React.useState([]);
  const [modaGroupID, setModalGroupID] = React.useState("");
  const [modalTimeZone, setModalTimeZone] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [Eopen, setEOpen] = React.useState(false);
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [modalIsCreate, setModalIsCreate] = React.useState(false);

  const [timeModalVisible, setTimeModalVisible] = React.useState(false);
  const [presetTimes, setPresetTimes] = React.useState([]);

  const [newReasonModalVisible, setNewReasonModalVisible] =
    React.useState(false);

  const [newPresetModalVisible, setNewPresetModalVisible] =
    React.useState(false);
  const [newPresetName, setNewPresetName] = React.useState("");
  const [newPresetStart, setNewPresetStart] = React.useState(new Date());
  const [newPresetStartPickerOpen, setNewPresetStartPickerOpen] =
    React.useState(false);
  const [newPresetEnd, setNewPresetEnd] = React.useState(new Date());
  const [newPresetEndPickerOpen, setNewPresetEndPickerOpen] =
    React.useState(false);

  const [refreshDate, setRefreshDate] = React.useState(false); //use this to refresh date when a preset date is selected

  const [showLoadingSpinner, setShowLoadingSpinner] = React.useState(false);

  const checkOnCall = () => {
    return new Promise((resolve, reject) => {
      setOnCallEvents([]);
      setContacts([]);
      setAccounts([]);
      setModalAccID("");
      setModalContactID("");
      setShowLoadingSpinner(true);
      fetchAPIJSON("api/v1/blaze/retOnCallEvents", store, props.navigation)
        .then((res) => {
          if (res.data.onCallEvents != undefined) {
            if (res.data.onCallEvents.length > 0) {
              let tempEvents = res.data.onCallEvents;
              let finalEvents = [];
              tempEvents.forEach((event) => {
                let newEvent = { ...event };
                newEvent.event_timezone = res?.data?.accounts.filter(
                  (acc) => acc.acc_id == newEvent.acc_id
                )[0].timezone;
                // console.log(
                //   "event timezone",
                //   newEvent.acc_id,
                //   newEvent.timezone
                // );
                finalEvents.push(newEvent);
              });

              setOnCallEvents(finalEvents);
            }
            if (res.data.contacts.length > 0) {
              setContacts(res.data.contacts);
              setModalContactID(res.data.contacts[0].contact_id);
            }
            if (res.data.reasons.length > 0) {
              setReasons(res.data.reasons);
            }

            setAccounts(res.data.accounts);
            setModalAccID(res.data.accounts[0].acc_id);
          }
          setShowLoadingSpinner(false);
          resolve();
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  };

  const handleSetOnCallContact = (
    event_id,
    acc_id,
    contact_id,
    reason_id,
    isCreate,
    event_group_id,
    timezone
  ) => {
    return new Promise((resolve, reject) => {
      const endpoint =
        isCreate == true ? "createOnCallEvent" : "updateOnCallEvent";

      const contactMatch = contacts.find(
        (contact) => contact?.contact_id == contact_id
      );
      let accIDsToSend = [];
      if (isCreate) {
        accIDsToSend =
          contactMatch?.acc_ids != null
            ? contactMatch?.acc_ids
            : [contactMatch.acc_id];
        // console.log("matched contact", contactMatch.acc_id, contactMatch.acc_ids);
      } else {
        accIDsToSend = modalGroupAccIds;
      }

      fetchAPIJSON(`api/v1/blaze/${endpoint}`, store, props.navigation, {
        event_id,
        acc_ids: accIDsToSend,
        contact_id,
        reason_id,
        startTime: dayjs(startDate).tz(timezone),
        endTime: dayjs(endDate).tz(timezone),
        event_group_id,
      })
        .then((res) => {
          setModalVisible(false);
          checkOnCall();
          toast.show({
            description: "On Call Changes Saved",
            placement: "top",
          });
          resolve();
        })
        .catch((e) => {
          console.log(e);
          reject();
        });
    });
  };

  const handleClickOnCallEvent = (
    acc_id,
    reason_id,
    currContact,
    event_id,
    startDate,
    endDate,
    isGroupEvent,
    group_id,
    timezone,
    group_acc_ids
  ) => {
    setModalGroupAccIds(group_acc_ids);
    setModalAccID(acc_id);
    setModalReasonID(reason_id);
    setModalContactID(currContact);
    setModalEventID(event_id);
    setModalTimeZone(timezone);
    setStartDate(new Date(startDate));
    setEndDate(new Date(endDate));
    setNewPresetName("");
    setNewPresetStart(new Date());
    setNewPresetEnd(new Date());
    setModalIsCreate(false);

    setModalIsGroupEvent(isGroupEvent);
    setModalGroupID(group_id);
    setRefreshDate(!refreshDate);
    setModalVisible(true);
  };

  const handleClickCreateOnCallEvent = (acc_id, timezone) => {
    setModalAccID(acc_id);
    setModalReasonID("");
    setModalContactID("");
    setModalEventID("");
    setModalTimeZone(timezone);
    setStartDate(new Date());
    setEndDate(new Date(new Date().setDate(new Date().getDate() + 1)));
    setNewPresetName("");
    setNewPresetStart(new Date());
    setNewPresetEnd(new Date());

    setModalIsGroupEvent(false);
    setModalIsCreate(true);
    setRefreshDate(!refreshDate);
    setModalVisible(true);
  };

  const deleteOnCallEvent = () => {
    setModalVisible(false);
    fetchAPIJSON("api/v1/blaze/deleteOnCallEvent", store, props.navigation, {
      event_id: modalEventID,
    })
      .then(() => {
        toast.show({
          description: "On Call Event Deleted",
          placement: "top",
        });
        checkOnCall();
      })
      .catch(() => {
        checkOnCall();
        toast.show({
          placement: "top",
          render: () => {
            return (
              <Box bg="red.500" px="2" py="1" rounded="sm" mb={5}>
                Failed to Delete On Call Event
              </Box>
            );
          },
        });
      });
  };

  const getOnCallPresetTimes = () => {
    fetchAPIJSON("api/v1/blaze/retOnCallPresetTimes", store, props.navigation, {
      acc_id: modalAccID,
    })
      .then((res) => {
        setPresetTimes(res?.data?.on_call_times);
      })
      .catch((err) => {});
  };

  const handleCreateNewPreset = () => {
    fetchAPIJSON(
      "api/v1/blaze/createOnCallPresetTime",
      store,
      props.navigation,
      {
        acc_id: modalAccID,
        start_time: newPresetStart,
        end_time: newPresetEnd,
        name: newPresetName,
      }
    )
      .then((res) => {
        setNewPresetModalVisible(false);
        setTimeModalVisible(true);
        getOnCallPresetTimes();
      })
      .catch((err) => {
        console.log(err);
      });
    setNewPresetName("");
    setNewPresetStart(new Date());
    setNewPresetEnd(new Date());
  };

  React.useEffect(() => {
    console.log("go");
    const unsubscribe = props.navigation.addListener("focus", () => {
      dayjs.extend(utc);
      dayjs.extend(timezone);
      dayjs.extend(advancedFormat);

      checkOnCall();
    });

    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe;
  }, [props.navigation]);

  React.useEffect(() => {
    const subscription = AppState.addEventListener("change", (nextAppState) => {
      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === "active"
      ) {
        dayjs.extend(utc);
        dayjs.extend(timezone);
        dayjs.extend(advancedFormat);
        checkOnCall();
      }

      appState.current = nextAppState;
      // setAppStateVisible(appState.current);
    });

    return () => {
      subscription.remove();
    };
  }, []);

  return (
    <Observer>
      {() => (
        <>
          <DashboardLayout
            title=""
            displayMenuButton={false}
            displayScreenTitle={false}
            displayAlternateMobileHeader={false}
            nav={props.navigation}
          >
            <ScrollView>
              {showLoadingSpinner && (
                <HStack space={2} my={4} justifyContent="center">
                  <Spinner />
                  <Heading color="primary.500" fontSize="md">
                    Loading On Call
                  </Heading>
                </HStack>
              )}
              {accounts.length > 0 &&
                accounts.map(({ acc_id, acc_name, acc_number, timezone }) => {
                  return (
                    <OnCallAccountCard
                      item={{ acc_id, acc_name, acc_number, timezone }}
                      contacts={contacts}
                      onCallEvents={onCallEvents}
                      key={acc_id}
                      openModal={handleClickOnCallEvent}
                      openCreateModal={handleClickCreateOnCallEvent}
                    ></OnCallAccountCard>
                  );
                })}
            </ScrollView>
            <Modal
              isOpen={modalVisible}
              onClose={() => setModalVisible(false)}
              avoidKeyboard
              size="lg"
            >
              <Modal.Content>
                <Modal.CloseButton />
                <Modal.Header>
                  {modalIsCreate == true ? "Create On Call" : "Change On Call"}
                </Modal.Header>
                <Modal.Body>
                  {/* {"Select a new on call contact for " + modalReasonID} */}
                  <VStack>
                    <Select
                      selectedValue={modalContactID}
                      accessibilityLabel="Choose Contact"
                      w={"100%"}
                      p={3}
                      placeholder="Choose Contact"
                      _selectedItem={{
                        bg: "primary.600",
                      }}
                      mt={1}
                      onValueChange={(itemValue) =>
                        setModalContactID(itemValue)
                      }
                    >
                      {contacts.length > 0 &&
                        contacts.map(
                          ({ contact_id, acc_id, first_name, last_name }) => {
                            if (acc_id == modalAccID) {
                              return (
                                <Select.Item
                                  key={contact_id}
                                  label={first_name + " " + last_name}
                                  value={contact_id}
                                />
                              );
                            }
                          }
                        )}
                    </Select>
                    <Select
                      selectedValue={modalReasonID}
                      accessibilityLabel="Choose Reason"
                      placeholder="Choose Reason"
                      p={3}
                      w={"100%"}
                      _selectedItem={{
                        bg: "primary.600",
                      }}
                      mt={2}
                      onValueChange={(itemValue) => {
                        if (itemValue != "new-reason") {
                          setModalReasonID(itemValue);
                        } else {
                          //logic to open new modal here
                          setNewReasonModalVisible(true);
                          setModalVisible(false);
                        }
                      }}
                    >
                      {reasons?.length > 0 &&
                        reasons.map(({ reason_id, acc_id, reason_name }) => {
                          if (acc_id == modalAccID) {
                            return (
                              <Select.Item
                                key={reason_id}
                                label={reason_name}
                                value={reason_id}
                              />
                            );
                          }
                        })}
                      <Select.Item
                        key={"new-reason"}
                        label={"Create New Reason..."}
                        value={"new-reason"}
                      ></Select.Item>
                    </Select>
                  </VStack>

                  <VStack alignItems={"center"} mt={2}>
                    <Pressable
                      onPress={() => {
                        setTimeModalVisible(true);
                        setModalVisible(false);
                        getOnCallPresetTimes();
                      }}
                      w={"100%"}
                    >
                      {modalTimeZone != null && (
                        <VStack
                          alignItems="center"
                          p={3}
                          borderRadius={5}
                          borderColor={"gray.300"}
                          borderWidth={1}
                          w={"100%"}
                        >
                          <Text w={"100%"}>
                            {modalTimeZone == null
                              ? "Start Date"
                              : dayjs(startDate)
                                  .tz(modalTimeZone)
                                  .format("MM-DD-YY hh:mm A z")}
                          </Text>

                          <Text mt={2} w={"100%"}>
                            {modalTimeZone == null
                              ? "End Date"
                              : dayjs(endDate)
                                  .tz(modalTimeZone)
                                  .format("MM-DD-YY hh:mm A z")}
                          </Text>
                        </VStack>
                      )}
                    </Pressable>
                  </VStack>
                </Modal.Body>
                <Modal.Footer>
                  <Button.Group space={3}>
                    {modalIsCreate == false && (
                      <Button
                        variant="solid"
                        colorScheme="red"
                        onPress={() => {
                          deleteOnCallEvent();
                        }}
                      >
                        Delete
                      </Button>
                    )}

                    <Button
                      variant="outline"
                      colorScheme="blueGray"
                      onPress={() => {
                        setModalVisible(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onPress={() => {
                        handleSetOnCallContact(
                          modalEventID,
                          modalAccID,
                          modalContactID,
                          modalReasonID,
                          modalIsCreate,
                          modaGroupID,
                          modalTimeZone
                        );
                      }}
                    >
                      Save
                    </Button>
                  </Button.Group>
                </Modal.Footer>
              </Modal.Content>
            </Modal>
            <Modal
              isOpen={timeModalVisible}
              onClose={() => {
                setTimeModalVisible(false);
                setModalVisible(true);
              }}
              avoidKeyboard
              size="lg"
            >
              <Modal.Content>
                <Modal.CloseButton />
                <Modal.Header>{"Set On Call Time"}</Modal.Header>
                <Modal.Body>
                  <VStack>
                    <HStack>
                      <Heading size={"sm"}>Preset Times</Heading>
                    </HStack>

                    <Divider my="2" orientation="horizontal" />
                    <HStack justifyContent={"flex-end"}>
                      <Pressable
                        mr="3"
                        onPress={() => {
                          setNewPresetModalVisible(true);
                          setTimeModalVisible(false);
                        }}
                      >
                        <Icon
                          as={MaterialIcons}
                          name="add"
                          size="6"
                          color={"green.600"}
                        />
                      </Pressable>
                    </HStack>
                    {presetTimes?.length > 0 && (
                      <ScrollView maxH={"48"}>
                        <VStack>
                          {presetTimes.map(({ name, start_time, end_time }) => {
                            return (
                              <Pressable
                                onPress={() => {
                                  //set start
                                  const currentStart =
                                    dayjs(startDate).tz(modalTimeZone);
                                  const presetStart =
                                    dayjs(start_time).tz(modalTimeZone);
                                  let newHour = presetStart.hour();
                                  let newMin = presetStart.minute();
                                  const newStart = currentStart
                                    .hour(newHour)
                                    .minute(newMin);
                                  setStartDate(newStart.toDate());
                                  setRefreshDate(!refreshDate);
                                  //set end
                                  const currentEnd =
                                    dayjs(endDate).tz(modalTimeZone);
                                  const presetEnd =
                                    dayjs(end_time).tz(modalTimeZone);

                                  newHour = presetEnd.hour();
                                  newMin = presetEnd.minute();
                                  const newEnd = currentEnd
                                    .hour(newHour)
                                    .minute(newMin);
                                  setEndDate(newEnd.toDate());
                                  setRefreshDate(!refreshDate);
                                }}
                              >
                                <VStack
                                  alignItems="center"
                                  px={3}
                                  py={2}
                                  my={1}
                                  borderRadius={5}
                                  borderColor={"gray.300"}
                                  borderWidth={1}
                                  w={"100%"}
                                  key={name}
                                >
                                  <Text w={"100%"}>{name}</Text>
                                  <HStack>
                                    <Text w={"100%"}>
                                      {dayjs(start_time)
                                        .tz(modalTimeZone)
                                        .format("hh:mm A z") +
                                        " - " +
                                        dayjs(end_time)
                                          .tz(modalTimeZone)
                                          .format("hh:mm A z")}
                                    </Text>
                                  </HStack>
                                </VStack>
                              </Pressable>
                            );
                          })}
                        </VStack>
                      </ScrollView>
                    )}
                    <Heading mt="2" size={"sm"}>
                      On Call Time
                    </Heading>
                    <Divider my="2" orientation="horizontal" />
                    <VStack alignItems={"center"} mt={2}>
                      <Pressable
                        onPress={() => {
                          setOpen(true);
                        }}
                      >
                        <HStack
                          mx={2}
                          alignItems="center"
                          p={3}
                          borderRadius={5}
                          borderColor={"gray.300"}
                          borderWidth={1}
                          w={"100%"}
                        >
                          {/* <Text w={"100%"}>
                            {modalTimeZone == null
                              ? "Start Date"
                              : dayjs(startDate)
                                  .tz(modalTimeZone)
                                  .format("MM-DD-YY hh:mm A z")}
                          </Text> */}

                          <WebDatePicker
                            value={startDate}
                            onTSChange={setStartDate}
                            refreshDate={refreshDate}
                          ></WebDatePicker>
                        </HStack>
                      </Pressable>
                      <Pressable
                        onPress={() => {
                          setEOpen(true);
                        }}
                      >
                        <HStack
                          mx={2}
                          alignItems="center"
                          p={3}
                          borderRadius={5}
                          borderColor={"gray.300"}
                          borderWidth={1}
                          w={"100%"}
                          mt={2}
                        >
                          {/* <Text w={"100%"}>
                            {modalTimeZone == null
                              ? "End Date"
                              : dayjs(endDate)
                                  .tz(modalTimeZone)
                                  .format("MM-DD-YY hh:mm A z")}
                          </Text> */}

                          <WebDatePicker
                            value={endDate}
                            onTSChange={setEndDate}
                            refreshDate={refreshDate}
                          ></WebDatePicker>
                        </HStack>
                      </Pressable>
                    </VStack>
                  </VStack>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    flex="1"
                    onPress={() => {
                      setTimeModalVisible(false);
                      setModalVisible(true);
                    }}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal.Content>
            </Modal>
            {newReasonModalVisible && (
              <NewReasonModal
                modalAccID={modalAccID}
                nav={props.navigation}
                closeModal={() => {
                  setNewReasonModalVisible(false);
                }}
                openPrevModal={() => {
                  setModalVisible(true);
                }}
                setModalContactID={(e) => {
                  setModalContactID(e);
                }}
                setModalReasonID={(e) => {
                  setModalReasonID(e);
                }}
                modalContactID={modalContactID}
                checkOnCall={checkOnCall}
              ></NewReasonModal>
            )}

            <Modal
              isOpen={newPresetModalVisible}
              onClose={() => {
                setNewPresetModalVisible(false);
                setTimeModalVisible(true);
              }}
              avoidKeyboard
              size="lg"
            >
              <Modal.Content>
                <Modal.CloseButton />
                <Modal.Header>{"Create New Preset Time"}</Modal.Header>
                <Modal.Body>
                  {/* {"Select a new on call contact for " + modalReasonID} */}
                  <VStack>
                    <Input
                      size="md"
                      placeholder="Preset Time Name"
                      value={newPresetName}
                      onChangeText={(e) => {
                        setNewPresetName(e);
                      }}
                    />
                    <Pressable
                      onPress={() => {
                        setNewPresetStartPickerOpen(true);
                      }}
                    >
                      <HStack
                        alignItems="center"
                        p={3}
                        borderRadius={5}
                        borderColor={"gray.300"}
                        borderWidth={1}
                        w={"100%"}
                        mt={2}
                      >
                        {/* <Text>
                          {modalTimeZone == null
                            ? "Start Date"
                            : dayjs(newPresetStart)
                                .tz(modalTimeZone)
                                .format("hh:mm A z")}
                        </Text> */}
                        <WebDatePicker
                          value={newPresetStart}
                          onTSChange={setNewPresetStart}
                          refreshDate={refreshDate}
                        ></WebDatePicker>
                      </HStack>
                    </Pressable>
                    <Pressable
                      onPress={() => {
                        setNewPresetEndPickerOpen(true);
                      }}
                    >
                      <HStack
                        alignItems="center"
                        p={3}
                        borderRadius={5}
                        borderColor={"gray.300"}
                        borderWidth={1}
                        w={"100%"}
                        mt={2}
                      >
                        {/* <Text>
                          {modalTimeZone == null
                            ? "End Date"
                            : dayjs(newPresetEnd)
                                .tz(modalTimeZone)
                                .format("hh:mm A z")}
                        </Text> */}
                        <WebDatePicker
                          value={newPresetEnd}
                          onTSChange={setNewPresetEnd}
                          refreshDate={refreshDate}
                        ></WebDatePicker>
                      </HStack>
                    </Pressable>
                  </VStack>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    flex="1"
                    onPress={() => {
                      handleCreateNewPreset();
                    }}
                  >
                    Save New Preset Time
                  </Button>
                </Modal.Footer>
              </Modal.Content>
            </Modal>
          </DashboardLayout>
          <MobileFooter nav={props.navigation} />
        </>
      )}
    </Observer>
  );
}
