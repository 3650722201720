import React, { useState, useEffect, useContext } from "react";
import { StoreContext } from "../../utilities/store";
import {
  Box,
  VStack,
  Button,
  Image,
  StatusBar,
  Center,
  Pressable,
  Stack,
  Text,
} from "native-base";
import { authLogin } from "../../utilities/network";
import * as Keychain from "react-native-keychain";
import OneSignal from "react-native-onesignal";

export default function Splash(props) {
  const store = React.useContext(StoreContext);

  const authSignIn = (email_in, pass_in) => {
    console.log("0");
    authLogin(email_in.toLowerCase(), pass_in)
      .then(async (authRes) => {
        console.log("1");
        store.accessToken = authRes.access_token;
        store.refreshToken = authRes.refresh_token;
        store.user_id = authRes.user_id;
        store.userFirstName = authRes.first_name;
        store.userLastName = authRes.last_name;
        store.userEmail = authRes.email;
        store.userPermissions = {
          access_on_call: authRes.userPermissions.access_on_call,
          access_all_messages: authRes.userPermissions.access_all_messages,
          access_ghost_call: authRes.userPermissions.access_ghost_call,
          access_call_recordings:
            authRes.userPermissions.access_call_recordings,
          access_datatables: authRes.userPermissions.access_datatables,
        };
        store.sip_id = authRes.sip_id;
        store.sip_name = authRes.sip_name;
        store.sip_username = authRes.sip_username;
        store.sip_password = authRes.sip_password;
        store.sip_from_number = authRes.sip_from_number;
        store.accs_array = authRes.userPermissions.accs;

        console.log("2/3 from splash");

        OneSignal.setExternalUserId(authRes.user_id);
        OneSignal.setEmail(authRes.email);

        console.log("4");

        OneSignal.promptForPushNotificationsWithUserResponse((response) => {
          console.log("5");
          console.log("Prompt response:", response);
          props.navigation.navigate("HomeScreen");
        });
      })
      .catch((err) => {
        console.log("sign in err", err);
        reportLoginFlowDebug(false, "login-1", {
          eInfo: err,
          email: email_in,
        });
        toast.show({
          description: "Invalid Login Details",
          placement: "top",
        });
      });
  };

  React.useEffect(() => {
    const unsubscribe = props.navigation.addListener("focus", async () => {
      console.log("trying to sign in");
      try {
        const creds = await Keychain.getGenericPassword({
          accessControl: Keychain.ACCESS_CONTROL.BIOMETRY_ANY,
          authenticationPrompt: { title: "Use Biometrics to Login" },
        });
        if (creds && creds.username != null) {
          console.log(
            "Credentials successfully loaded for user " + creds.username
          );
          authSignIn(creds.username, creds.password);
        } else {
          console.log("No credentials stored");
        }
      } catch (error) {
        console.log("Keychain couldn't be accessed!", error);
      }
    });

    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe;
  }, [props.navigation]);

  return (
    <>
      <StatusBar
        translucent
        backgroundColor="transparent"
        barStyle="light-content"
      />
      <Box
        safeAreaTop
        _light={{ bg: "primary.900" }}
        _dark={{ bg: "coolGray.900" }}
      />
      <Center
        my="auto"
        _dark={{ bg: "coolGray.900" }}
        _light={{ bg: "primary.900" }}
        flex="1"
        p={{ md: 8 }}
      >
        <Stack
          flexDirection={{ base: "column", md: "row" }}
          w="100%"
          maxW={{ md: "1016px" }}
          flex={{ base: "1", md: "none" }}
        >
          <Center w="100%" flex={1}>
            <Box
              maxW="500"
              w="100%"
              px={{
                base: "4",
                md: "20",
              }}
              py={{
                base: "6",
                md: "20",
              }}
              rounded={{ md: "xl" }}
              _light={{ bg: { md: "primary.700" } }}
              _dark={{ bg: { md: "coolGray.800" } }}
            >
              <Box
                mb={{
                  base: "10",
                  md: "16",
                }}
                flexDirection={{
                  base: "column",
                  md: "row",
                }}
                alignItems="center"
                justifyContent="center"
              >
                <Image
                  h="24"
                  w={{ base: "64", md: "72" }}
                  resizeMode="contain"
                  alt="NativeBase Startup plus"
                  source={require("../../assets/BlazeRelayLogo.png")}
                />
              </Box>
              <VStack space="4">
                <Button
                  size="lg"
                  onPress={() => {
                    props.navigation.navigate("SignIn");
                  }}
                  _text={{
                    color: "primary.900",
                  }}
                  _hover={{ bg: "coolGray.200" }}
                  bg="coolGray.100"
                >
                  LOGIN
                </Button>

                <Button
                  size="lg"
                  _text={{
                    color: "coolGray.50",
                  }}
                  onPress={() => {
                    props.navigation.navigate("SetupCode");
                  }}
                  variant="outline"
                  colorScheme="coolGray"
                  borderColor="coolGray.50"
                >
                  SIGN UP
                </Button>
                <Pressable
                  mt="12"
                  backgroundColor={"pink"}
                  onPress={() => {
                    props.navigation.navigate("ResetPasswordScreen");
                  }}
                >
                  <Text alignSelf={"center"} color="white">
                    Reset Password
                  </Text>
                </Pressable>
              </VStack>
            </Box>
          </Center>
        </Stack>
      </Center>
    </>
  );
}
