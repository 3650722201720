import React, { useState, useEffect, useMemo } from "react";
import { Observer, useObserver } from "mobx-react";
import { MaterialIcons } from "@expo/vector-icons";

import "intl";
import "intl/locale-data/jsonp/en";

// import PushNotificationIOS from "@react-native-community/push-notification-ios";
import { requestNotifications } from "react-native-permissions";

import {
  Box,
  HStack,
  Icon,
  Text,
  VStack,
  Button,
  Avatar,
  Image,
  ScrollView,
  Pressable,
  Hidden,
  Select,
  Center,
  IconButton,
  ArrowBackIcon,
  FormControl,
  Modal,
  Input,
  Checkbox,
  useToast,
  AlertDialog,
  Spinner,
  Heading,
  Switch,
} from "native-base";

import { StoreContext } from "../../utilities/store";
import DashboardLayout from "../../layouts/DashboardLayout";
import { fetchAPIJSON, reportDeleteAcc } from "../../utilities/network";

// function MobileFooter(props) {
//   const store = props.store;

//   return (
//     <Hidden from="md">
//       <HStack
//         justifyContent="space-between"
//         safeAreaBottom
//         h="20"
//         width="100%"
//         overflow="hidden"
//         borderTopRightRadius={"xl"}
//         borderTopLeftRadius={"xl"}
//         _light={{ backgroundColor: "primary.600" }}
//         _dark={{ backgroundColor: "coolGray.800" }}
//       >
//         <Button
//           variant="ghost"
//           flex={1}
//           colorScheme="coolGray"
//           _stack={{
//             flexDirection: "column",
//           }}
//           startIcon={<Icon as={MaterialIcons} name="message" size="5" />}
//           onPress={() => {
//             props.nav.navigate("HomeScreen");
//           }}
//         ></Button>
//         {store.accessOnCall && (
//           <Button
//             variant="ghost"
//             flex={1}
//             _stack={{
//               flexDirection: "column",
//             }}
//             colorScheme="coolGray"
//             startIcon={
//               <Icon as={MaterialIcons} name="perm-contact-calendar" size="5" />
//             }
//             onPress={() => {
//               props.nav.navigate("OnCallScreen");
//             }}
//           ></Button>
//         )}
//         {store.accessGhostCall && (
//           <Button
//             variant="ghost"
//             flex={1}
//             colorScheme="coolGray"
//             _stack={{
//               flexDirection: "column",
//             }}
//             startIcon={
//               <Icon as={MaterialIcons} name="phone-in-talk" size="5" />
//             }
//             onPress={() => {
//               props.nav.navigate("GhostCallScreen");
//             }}
//           ></Button>
//         )}
//         {store.accessCallRecordings && (
//           <Button
//             variant="ghost"
//             flex={1}
//             colorScheme="coolGray"
//             _stack={{
//               flexDirection: "column",
//             }}
//             startIcon={
//               <Icon as={MaterialIcons} name="multitrack-audio" size="5" />
//             }
//             onPress={() => {
//               props.nav.navigate("CallRecordingsScreen");
//             }}
//           ></Button>
//         )}
//         {store.accessDatatables && (
//           <Button
//             variant="ghost"
//             flex={1}
//             colorScheme="coolGray"
//             _stack={{
//               flexDirection: "column",
//             }}
//             startIcon={<Icon as={MaterialIcons} name="view-list" size="5" />}
//             onPress={() => {
//               props.nav.navigate("DataTablesScreen");
//             }}
//           ></Button>
//         )}
//         <Button
//           variant="ghost"
//           flex={1}
//           colorScheme="coolGray"
//           _stack={{
//             flexDirection: "column",
//           }}
//           startIcon={
//             <Icon as={MaterialIcons} name="settings" size="5" color="white" />
//           }
//         ></Button>
//       </HStack>
//     </Hidden>
//   );
// }

const NotificationOptionToggle = ({
  optionTitle,
  isEnabled,
  switchOn,
  onSwitchToggle,
  isCritical,
}) => {
  return (
    <HStack
      borderTopWidth={1}
      borderBottomWidth={1}
      borderColor={"gray.200"}
      width={"100%"}
      alignItems={"center"}
    >
      <Text
        fontsize={"md"}
        px={4}
        py={3}
        color={isEnabled == true ? "black" : "gray.400"}
      >
        {optionTitle}
      </Text>
      <Switch
        ml={"auto"}
        mr={2}
        size={"lg"}
        isDisabled={!isEnabled}
        value={switchOn == true && isEnabled == true ? true : false}
        onToggle={(e) => {
          if (isCritical && e == true) {
            // if this is a critical switch and user turns it on
            console.log("requesting critical alert perms");
            requestNotifications(["criticalAlert"]).then(
              ({ status, settings }) => {
                console.log("status", status);
                console.log("settings", settings);
                // …
              }
            );
          }
          onSwitchToggle(e);
        }}
      ></Switch>
    </HStack>
  );
};

const NotificationOptionSelect = ({ optionTitle, isEnabled }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <Pressable
      onPress={() => {
        if (isEnabled) {
          setIsOpen(!isOpen);
        }
      }}
    >
      <VStack borderTopWidth={1} borderBottomWidth={1} borderColor={"gray.200"}>
        <HStack width={"100%"} alignItems={"center"}>
          <Text
            fontsize={"md"}
            px={4}
            py={3}
            color={isEnabled == true ? "black" : "gray.400"}
          >
            {optionTitle}
          </Text>
          <Icon
            as={MaterialIcons}
            name={
              isEnabled == true && isOpen == true
                ? "keyboard-arrow-down"
                : "keyboard-arrow-right"
            }
            size="5"
            color="gray.400"
            ml={"auto"}
            mr={2}
          />
        </HStack>
        {isOpen && isEnabled && (
          <VStack>
            <Text ml={4} mb={2}>
              Customizable sounds coming soon!
            </Text>
          </VStack>
        )}
      </VStack>
    </Pressable>
  );
};

const NotificationTypeOptions = ({
  title,
  pushEnabled,
  setPushEnabled,
  criticalEnabled,
  setCriticalEnabled,
}) => {
  return (
    <VStack mt={"8"}>
      <Text fontWeight={"semibold"} fontSize={"lg"} py={2} px={4} mb={2}>
        {title}
      </Text>
      <NotificationOptionToggle
        optionTitle={"Send Push Notification"}
        switchOn={pushEnabled}
        onSwitchToggle={setPushEnabled}
        isEnabled={true}
        isCritical={false}
      ></NotificationOptionToggle>
      <NotificationOptionToggle
        optionTitle={"Send Critical Alert"}
        switchOn={criticalEnabled}
        onSwitchToggle={setCriticalEnabled}
        isEnabled={pushEnabled}
        isCritical={true}
      ></NotificationOptionToggle>
      <NotificationOptionSelect
        optionTitle={"Notification Sound"}
        isEnabled={pushEnabled}
      ></NotificationOptionSelect>
    </VStack>
  );
};

export default function NotifCustomizeScreen(props) {
  const store = React.useContext(StoreContext);
  const toast = useToast();

  const [generalPushEnabled, setGeneralPushEnabled] = React.useState(false);
  const [generalCriticalEnabled, setGeneralCriticalEnabled] =
    React.useState(false);
  const [urgentPushEnabled, setUrgentPushEnabled] = React.useState(false);
  const [urgentCriticalEnabled, setUrgentCriticalEnabled] =
    React.useState(false);

  const [showLoadingSpinner, setShowLoadingSpinner] = React.useState(false);

  const loadUserNotifPreferences = () => {
    return new Promise((resolve, reject) => {
      setGeneralPushEnabled(false);
      setGeneralCriticalEnabled(false);
      setUrgentPushEnabled(false);
      setUrgentCriticalEnabled(false);
      setShowLoadingSpinner(true);
      fetchAPIJSON("api/v1/blaze/retNotifPrefs", store, props.navigation)
        .then((res) => {
          console.log(res);
          if (res.data.prefs != undefined) {
            console.log(res.data.prefs);
            setShowLoadingSpinner(false);
            const prefs = res.data?.prefs;
            setGeneralPushEnabled(prefs?.general_notif_push);
            setGeneralCriticalEnabled(prefs?.general_notif_critical);
            setUrgentPushEnabled(prefs?.urgent_notif_push);
            setUrgentCriticalEnabled(prefs?.urgent_notif_critical);
            resolve();
          } else {
            reject();
          }
        })
        .catch((err) => {
          console.log("err", err);
          reject();
        });
    });
  };

  const saveUserNotifPreferences = () => {
    return new Promise((resolve, reject) => {
      setShowLoadingSpinner(true);
      fetchAPIJSON("api/v1/blaze/updateNotifPrefs", store, props.navigation, {
        general_notif_push: generalPushEnabled,
        // if general push is disabled, critical is always false
        general_notif_critical:
          generalPushEnabled == false ? false : generalCriticalEnabled,
        general_notif_sound: null,
        urgent_notif_push: urgentPushEnabled,
        urgent_notif_critical:
          urgentPushEnabled == false ? false : urgentCriticalEnabled,
        urgent_notif_sound: null,
      })
        .then((res) => {
          console.log(res);

          setShowLoadingSpinner(false);

          resolve();
        })
        .catch((err) => {
          console.log("err", err);
          reject();
        });
    });
  };

  React.useEffect(() => {
    const unsubscribe = props.navigation.addListener("focus", () => {
      loadUserNotifPreferences();
    });

    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe;
  }, [props.navigation]);
  return (
    <Observer>
      {() => (
        <>
          <DashboardLayout
            title=""
            displayMenuButton={false}
            displayScreenTitle={false}
            displayAlternateMobileHeader={false}
            nav={props.navigation}
          >
            <ScrollView>
              <VStack mt={10}>
                <Text
                  fontSize={"2xl"}
                  fontWeight={"semibold"}
                  paddingLeft={"4"}
                >
                  Notification Preferences
                </Text>
                {showLoadingSpinner && (
                  <HStack space={2} my={4} justifyContent="center">
                    <Spinner />
                    <Heading color="primary.500" fontSize="md">
                      Loading Preferences
                    </Heading>
                  </HStack>
                )}
                {!showLoadingSpinner && (
                  <VStack>
                    <NotificationTypeOptions
                      title={"General Notifications"}
                      pushEnabled={generalPushEnabled}
                      setPushEnabled={setGeneralPushEnabled}
                      criticalEnabled={generalCriticalEnabled}
                      setCriticalEnabled={setGeneralCriticalEnabled}
                    ></NotificationTypeOptions>
                    <NotificationTypeOptions
                      title={"Urgent Notifications"}
                      pushEnabled={urgentPushEnabled}
                      setPushEnabled={setUrgentPushEnabled}
                      criticalEnabled={urgentCriticalEnabled}
                      setCriticalEnabled={setUrgentCriticalEnabled}
                    ></NotificationTypeOptions>
                  </VStack>
                )}
              </VStack>
            </ScrollView>
            <HStack justifyContent={"space-evenly"} mb={"12"}>
              <Button
                w={"32"}
                colorScheme="danger"
                onPress={() => {
                  props.navigation.navigate("SettingsScreen");
                }}
              >
                Cancel
              </Button>
              <Button
                w={"32"}
                onPress={() => {
                  saveUserNotifPreferences().then(() => {
                    props.navigation.navigate("SettingsScreen");
                  });
                }}
              >
                Save
              </Button>
            </HStack>
          </DashboardLayout>
          {/* <MobileFooter nav={props.navigation} store={store} /> */}
        </>
      )}
    </Observer>
  );
}
