import React, { useState, useEffect, useMemo } from "react";
import { Observer, useObserver } from "mobx-react";
import { MaterialIcons } from "@expo/vector-icons";

import "intl";
import "intl/locale-data/jsonp/en";

import {
  Box,
  HStack,
  Icon,
  Text,
  VStack,
  Button,
  Avatar,
  Image,
  ScrollView,
  Pressable,
  Hidden,
  Select,
  Center,
  IconButton,
  ArrowBackIcon,
  FormControl,
  Modal,
  Input,
  Checkbox,
  useToast,
  AlertDialog,
} from "native-base";

import { StoreContext } from "../../utilities/store";
import DashboardLayout from "../../layouts/DashboardLayout";
import { fetchAPIJSON, reportDeleteAcc } from "../../utilities/network";

function MobileFooter(props) {
  const store = props.store;

  return (
    <Hidden from="md">
      <HStack
        justifyContent="space-between"
        safeAreaBottom
        h="20"
        width="100%"
        overflow="hidden"
        borderTopRightRadius={"xl"}
        borderTopLeftRadius={"xl"}
        _light={{ backgroundColor: "primary.600" }}
        _dark={{ backgroundColor: "coolGray.800" }}
      >
        <Button
          variant="ghost"
          flex={1}
          colorScheme="coolGray"
          _stack={{
            flexDirection: "column",
          }}
          startIcon={<Icon as={MaterialIcons} name="message" size="5" />}
          onPress={() => {
            props.nav.navigate("HomeScreen");
          }}
        ></Button>
        {store.accessOnCall && (
          <Button
            variant="ghost"
            flex={1}
            _stack={{
              flexDirection: "column",
            }}
            colorScheme="coolGray"
            startIcon={
              <Icon as={MaterialIcons} name="perm-contact-calendar" size="5" />
            }
            onPress={() => {
              props.nav.navigate("OnCallScreen");
            }}
          ></Button>
        )}
        {store.accessGhostCall && (
          <Button
            variant="ghost"
            flex={1}
            colorScheme="coolGray"
            _stack={{
              flexDirection: "column",
            }}
            startIcon={
              <Icon as={MaterialIcons} name="phone-in-talk" size="5" />
            }
            onPress={() => {
              props.nav.navigate("GhostCallScreen");
            }}
          ></Button>
        )}
        {store.accessCallRecordings && (
          <Button
            variant="ghost"
            flex={1}
            colorScheme="coolGray"
            _stack={{
              flexDirection: "column",
            }}
            startIcon={
              <Icon as={MaterialIcons} name="multitrack-audio" size="5" />
            }
            onPress={() => {
              props.nav.navigate("CallRecordingsScreen");
            }}
          ></Button>
        )}
        {store.accessDatatables && (
          <Button
            variant="ghost"
            flex={1}
            colorScheme="coolGray"
            _stack={{
              flexDirection: "column",
            }}
            startIcon={<Icon as={MaterialIcons} name="view-list" size="5" />}
            onPress={() => {
              props.nav.navigate("DataTablesScreen");
            }}
          ></Button>
        )}
        <Button
          variant="ghost"
          flex={1}
          colorScheme="coolGray"
          _stack={{
            flexDirection: "column",
          }}
          startIcon={
            <Icon as={MaterialIcons} name="settings" size="5" color="white" />
          }
        ></Button>
      </HStack>
    </Hidden>
  );
}

const SettingsButton = ({ title, actionOnPress }) => {
  return (
    <Pressable
      onPress={() => {
        actionOnPress();
      }}
    >
      <HStack
        borderTopWidth={1}
        borderBottomWidth={1}
        borderColor={"gray.400"}
        width={"100%"}
        mt={2}
        alignItems={"center"}
      >
        <Text fontSize={"lg"} py={2} px={4}>
          {title}
        </Text>
        <Icon
          as={MaterialIcons}
          name="keyboard-arrow-right"
          size="5"
          color="gray.400"
          ml={"auto"}
          mr={2}
        />
      </HStack>
    </Pressable>
  );
};

export default function SettingsScreen(props) {
  const store = React.useContext(StoreContext);
  const toast = useToast();

  const [isDeleteAccountOpen, setIsDeleteAccountOpen] = React.useState(false);
  const [isDeleteAccountOpen2, setIsDeleteAccountOpen2] = React.useState(false);
  const [isDeleteAccountOpen3, setIsDeleteAccountOpen3] = React.useState(false);
  const [deleteText, setDeleteText] = React.useState("");

  const [logoutOpen, setLogoutOpen] = React.useState(false);

  React.useEffect(() => {
    const unsubscribe = props.navigation.addListener("focus", () => {
      // do tab stuff
    });

    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe;
  }, [props.navigation]);
  return (
    <Observer>
      {() => (
        <>
          <DashboardLayout
            title=""
            displayMenuButton={false}
            displayScreenTitle={false}
            displayAlternateMobileHeader={false}
            nav={props.navigation}
          >
            <ScrollView>
              <VStack mt={10}>
                <Text
                  fontSize={"2xl"}
                  fontWeight={"semibold"}
                  paddingLeft={"4"}
                  mb={4}
                >
                  Settings
                </Text>
                <SettingsButton
                  title={"Change Notification Preferences"}
                  actionOnPress={() => {
                    // if (!toast.isActive("Notif-Pref")) {
                    //   toast.show({
                    //     description: "Notification Options Coming Soon!",
                    //     placement: "top",
                    //     id: "Notif-Pref",
                    //   });
                    // }
                    props.navigation.navigate("NotifCustomizeScreen");
                  }}
                ></SettingsButton>
                <SettingsButton
                  title={"Delete Account"}
                  actionOnPress={() => {
                    setIsDeleteAccountOpen(true);
                  }}
                ></SettingsButton>
                <SettingsButton
                  title={"Logout"}
                  actionOnPress={() => {
                    setLogoutOpen(true);
                  }}
                ></SettingsButton>
              </VStack>
            </ScrollView>
            <AlertDialog
              isOpen={isDeleteAccountOpen}
              onClose={() => {
                setIsDeleteAccountOpen(false);
              }}
            >
              <AlertDialog.Content>
                <AlertDialog.CloseButton />
                <AlertDialog.Header>Delete Account</AlertDialog.Header>
                <AlertDialog.Body>
                  This will delete all data related to your account. This
                  process is unreversable. Are you sure you want to continue?
                </AlertDialog.Body>
                <AlertDialog.Footer>
                  <Button.Group space={2}>
                    <Button
                      variant="unstyled"
                      colorScheme="coolGray"
                      onPress={() => {
                        setIsDeleteAccountOpen(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      colorScheme="danger"
                      onPress={() => {
                        setIsDeleteAccountOpen(false);
                        setIsDeleteAccountOpen2(true);
                      }}
                    >
                      Delete my Account
                    </Button>
                  </Button.Group>
                </AlertDialog.Footer>
              </AlertDialog.Content>
            </AlertDialog>
            <AlertDialog
              isOpen={isDeleteAccountOpen2}
              onClose={() => {
                setIsDeleteAccountOpen2(false);
              }}
            >
              <AlertDialog.Content>
                <AlertDialog.CloseButton />
                <AlertDialog.Header>Are you sure?</AlertDialog.Header>
                <AlertDialog.Body>
                  <Text>
                    Please type "DELETE" to confirm permanent deletion of your
                    account.
                  </Text>
                  <Input
                    placeholder="DELETE"
                    mb={2}
                    mt={6}
                    onChangeText={(e) => {
                      setDeleteText(e);
                    }}
                    value={deleteText}
                  ></Input>
                </AlertDialog.Body>
                <AlertDialog.Footer>
                  <Button.Group space={2}>
                    <Button
                      variant="unstyled"
                      colorScheme="coolGray"
                      onPress={() => {
                        setIsDeleteAccountOpen2(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onPress={() => {
                        if (deleteText == "DELETE") {
                          setIsDeleteAccountOpen3(true);
                          setIsDeleteAccountOpen2(false);
                          reportDeleteAcc(
                            store.userEmail,
                            store.userFirstName,
                            store.userLastName
                          );
                        } else {
                          if (!toast.isActive("Delete-Acc")) {
                            toast.show({
                              description: "Please type 'DELETE' to proceed.",
                              placement: "top",
                              id: "Delete-Acc",
                            });
                          }
                        }
                      }}
                      colorScheme="danger"
                    >
                      Delete my Account
                    </Button>
                  </Button.Group>
                </AlertDialog.Footer>
              </AlertDialog.Content>
            </AlertDialog>
            <AlertDialog isOpen={isDeleteAccountOpen3}>
              <AlertDialog.Content>
                <AlertDialog.Header>
                  Account Deletion in Progress
                </AlertDialog.Header>
                <AlertDialog.Body>
                  <Text>
                    Your account has been flagged for deletion. All data related
                    to your account will be fully deleted within 30 days. You
                    will now be logged out.
                  </Text>
                </AlertDialog.Body>
                <AlertDialog.Footer>
                  <Button.Group space={2}>
                    <Button
                      colorScheme="danger"
                      onPress={() => {
                        setIsDeleteAccountOpen3(false);
                        store.signOut();
                        props.navigation.navigate("Splash");
                      }}
                    >
                      Okay
                    </Button>
                  </Button.Group>
                </AlertDialog.Footer>
              </AlertDialog.Content>
            </AlertDialog>
            <Modal
              isOpen={logoutOpen}
              onClose={() => setLogoutOpen(false)}
              avoidKeyboard
              size="xl"
              w={{ md: "40%" }}
              alignSelf={"center"}
            >
              <Modal.Content>
                <Modal.Header>Confirm Logout</Modal.Header>
                <Modal.Body>
                  <Text fontSize={"lg"}>Are you sure you want to logout?</Text>
                  <Text fontSize={"md"} mt={2}>
                    You'll need to re-enter your password next time you sign in.
                  </Text>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="unstyled"
                    colorScheme="coolGray"
                    flex="1"
                    mx={2}
                    onPress={() => {
                      setLogoutOpen(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    colorScheme="danger"
                    flex="1"
                    mx={2}
                    onPress={() => {
                      setLogoutOpen(false);
                      store.signOut();
                      props.navigation.navigate("Splash");
                    }}
                  >
                    Logout
                  </Button>
                </Modal.Footer>
              </Modal.Content>
            </Modal>
          </DashboardLayout>
          <MobileFooter nav={props.navigation} store={store} />
        </>
      )}
    </Observer>
  );
}
