import React from "react";
import {
  Box,
  HStack,
  Icon,
  Text,
  VStack,
  Button,
  Avatar,
  Image,
  ScrollView,
  Pressable,
  Hidden,
  Spinner,
  Heading,
  Center,
  IconButton,
  ArrowBackIcon,
  FormControl,
  Select,
  Modal,
  Input,
  useToast,
} from "native-base";

export default function WebDatePicker({ value, onTSChange, refreshDate }) {
  const [year, setYear] = React.useState("");
  const [month, setMonth] = React.useState("");
  const [day, setDay] = React.useState("");

  const [hour, setHour] = React.useState("");
  const [min, setMin] = React.useState("");

  const [dayPeriod, setDayPeriod] = React.useState("");

  const [isValid, setIsValid] = React.useState(true);

  React.useEffect(() => {
    const ts = value;
    setDay(ts.getDate());
    setMonth(ts.getMonth() + 1);
    setYear(ts.getFullYear());
    setHour(ts.getHours() % 12 == 0 ? "12" : ts.getHours() % 12);
    setMin(ts.getMinutes().toString().padStart(2, "0"));
    setDayPeriod(ts.getHours() < 12 ? "AM" : "PM");
    setIsValid(true);
  }, [refreshDate]);

  React.useEffect(() => {
    let newDate = new Date(
      Date.parse(`${month}/${day}/${year} ${hour}:${min} ${dayPeriod}`)
    );
    const newDateValid = newDate instanceof Date && !isNaN(newDate.valueOf());
    if (newDateValid) {
      //   console.log("ts type", onTSChange);
      onTSChange(newDate);
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [year, month, day, hour, min, dayPeriod]);

  return (
    <VStack mt={4}>
      {isValid == false && <Text color={"red.400"}>Invalid Date!</Text>}
      <HStack>
        <Input
          w={"12"}
          size={"lg"}
          fontSize={"sm"}
          value={month}
          maxLength={2}
          onChangeText={(t) => {
            setMonth(t);
          }}
        ></Input>
        <Text fontSize={"xl"} mx={"1"}>
          /
        </Text>
        <Input
          w={"12"}
          size={"lg"}
          fontSize={"sm"}
          value={day}
          maxLength={2}
          onChangeText={(t) => {
            setDay(t);
          }}
        ></Input>
        <Text fontSize={"xl"} mx={"1"}>
          /
        </Text>
        <Input
          w={"20"}
          size={"lg"}
          fontSize={"sm"}
          value={year}
          maxLength={4}
          onChangeText={(t) => {
            setYear(t);
          }}
        ></Input>
        <Input
          ml={"6"}
          w={"12"}
          size={"lg"}
          fontSize={"sm"}
          maxLength={2}
          value={hour}
          onChangeText={(t) => {
            setHour(t);
          }}
        ></Input>
        <Text fontSize={"xl"} mx={"1"}>
          :
        </Text>
        <Input
          w={"12"}
          size={"lg"}
          fontSize={"sm"}
          maxLength={2}
          value={min}
          onChangeText={(t) => {
            setMin(t);
          }}
        ></Input>
        <Select
          ml={"2"}
          size={"lg"}
          fontSize={"sm"}
          selectedValue={dayPeriod}
          w={"24"}
          _selectedItem={{
            bg: "primary.600",
          }}
          onValueChange={(itemValue) => setDayPeriod(itemValue)}
        >
          <Select.Item key={"AM"} label={"AM"} value={"AM"} />
          <Select.Item key={"PM"} label={"PM"} value={"PM"} />
        </Select>
      </HStack>
    </VStack>
  );
}
