import React from "react";
import { Observer, useObserver } from "mobx-react";
import "intl";
import "intl/locale-data/jsonp/en";

import {
  Box,
  HStack,
  Icon,
  Text,
  VStack,
  Button,
  Badge,
  Image,
  ScrollView,
  Pressable,
  Hidden,
  Center,
  Spinner,
  Heading,
  IconButton,
  ArrowBackIcon,
  FormControl,
  Modal,
  Input,
  Select,
  useToast,
} from "native-base";
import { TextInput, FlatList } from "react-native";
import {
  Entypo,
  Feather,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
  SimpleLineIcons,
} from "@expo/vector-icons";

import { StoreContext } from "../../utilities/store";
import DashboardLayout from "../../layouts/DashboardLayout";
import { fetchAPIJSON } from "../../utilities/network";
import { Platform, AppState } from "react-native";

// use this toast https://www.npmjs.com/package/react-native-toast-message

function MobileFooter(props) {
  const store = React.useContext(StoreContext);

  const [logoutOpen, setLogoutOpen] = React.useState(false);
  return (
    <Hidden from="md">
      <HStack
        justifyContent="space-between"
        safeAreaBottom
        h="20"
        width="100%"
        overflow="hidden"
        // alignSelf="center"
        borderTopLeftRadius={"xl"}
        borderTopRightRadius={"xl"}
        backgroundColor={"primary.600"}
      >
        <Button
          variant="ghost"
          flex={1}
          colorScheme="coolGray"
          _stack={{
            flexDirection: "column",
          }}
          startIcon={
            <Icon as={MaterialIcons} name="message" size="5" color="white" />
          }
          onPress={() => {}}
        ></Button>
        {store.accessOnCall && (
          <Button
            variant="ghost"
            flex={1}
            _stack={{
              flexDirection: "column",
            }}
            colorScheme="coolGray"
            startIcon={
              <Icon as={MaterialIcons} name="perm-contact-calendar" size="5" />
            }
            onPress={() => {
              props.nav.navigate("OnCallScreen");
            }}
          ></Button>
        )}
        {store.accessGhostCall && (
          <Button
            variant="ghost"
            flex={1}
            colorScheme="coolGray"
            _stack={{
              flexDirection: "column",
            }}
            startIcon={
              <Icon as={MaterialIcons} name="phone-in-talk" size="5" />
            }
            onPress={() => {
              props.nav.navigate("GhostCallScreen");
            }}
          ></Button>
        )}
        {store.accessCallRecordings && (
          <Button
            variant="ghost"
            flex={1}
            colorScheme="coolGray"
            _stack={{
              flexDirection: "column",
            }}
            startIcon={
              <Icon as={MaterialIcons} name="multitrack-audio" size="5" />
            }
            onPress={() => {
              props.nav.navigate("CallRecordingsScreen");
            }}
          ></Button>
        )}
        {store.accessDatatables && (
          <Button
            variant="ghost"
            flex={1}
            colorScheme="coolGray"
            _stack={{
              flexDirection: "column",
            }}
            startIcon={<Icon as={MaterialIcons} name="view-list" size="5" />}
            onPress={() => {
              props.nav.navigate("DataTablesScreen");
            }}
          ></Button>
        )}
        {/* <Button
          variant="ghost"
          flex={1}
          colorScheme="coolGray"
          _stack={{
            flexDirection: "column",
          }}
          startIcon={<Icon as={MaterialIcons} name="exit-to-app" size="5" />}
          onPress={() => {
            setLogoutOpen(true);
          }}
        ></Button>

        <Modal
          isOpen={logoutOpen}
          onClose={() => setLogoutOpen(false)}
          avoidKeyboard
          size="xl"
          w={{ md: "40%" }}
          alignSelf={"center"}
        >
          <Modal.Content>
            <Modal.Header>Confirm Logout</Modal.Header>
            <Modal.Body>
              <Text fontSize={"lg"}>Are you sure you want to logout?</Text>
              <Text fontSize={"md"} mt={2}>
                You'll need to re-enter your password next time you sign in.
              </Text>
            </Modal.Body>
            <Modal.Footer>
              <Button
                flex="1"
                mx={2}
                onPress={() => {
                  setLogoutOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                flex="1"
                mx={2}
                onPress={() => {
                  setLogoutOpen(false);
                  store.signOut();
                  props.nav.navigate("Splash");
                }}
              >
                Logout
              </Button>
            </Modal.Footer>
          </Modal.Content>
        </Modal> */}
        <Button
          variant="ghost"
          flex={1}
          colorScheme="coolGray"
          _stack={{
            flexDirection: "column",
          }}
          startIcon={<Icon as={MaterialIcons} name="settings" size="5" />}
          onPress={() => {
            props.nav.navigate("SettingsScreen");
          }}
        ></Button>
      </HStack>
    </Hidden>
  );
}

const MessageNote = ({ isSystem, note, timestamp }) => {
  return (
    <VStack mt={4}>
      <HStack>
        {isSystem && (
          <Badge colorScheme="info" variant={"solid"}>
            System
          </Badge>
        )}
        {!isSystem && (
          <Badge colorScheme="warning" variant={"solid"}>
            Agent
          </Badge>
        )}
        <Text>
          {" "}
          {new Date(timestamp).toLocaleString(navigator.language, {
            hour: "2-digit",
            minute: "2-digit",
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            timeZoneName: "short",
          })}
        </Text>
      </HStack>
      <Text ml={4}>{note}</Text>
    </VStack>
  );
};

const createMessageText = (text, onClickPhoneNumber) => {
  let textToProcess = text;
  let textComponents = [];

  while (textToProcess?.includes("PN<")) {
    const numberTest = textToProcess.match(new RegExp("PN<" + "(.*)" + ">/PN"));

    if (numberTest != null) {
      // console.log("numTest", numberTest.length);
      const phoneString = numberTest[0].replace("PN<", "").replace(">/PN", "");
      let splitText = textToProcess.split(phoneString);
      // console.log("splitting on", phoneString);

      //this stops text from breaking if the same PN appears twice in one message
      if (splitText.length > 2) {
        let tempArray = [...splitText].slice(1);

        tempArray = tempArray.join(phoneString);
        splitText[1] = tempArray;
      }

      textComponents.push(
        <Text>{splitText[0].replace("PN<", "").replace(">/PN", "")}</Text>
      );
      textComponents.push(
        <Pressable
          // backgroundColor={"pink.400"}
          onPress={() => {
            if (Platform.OS != "web") {
              onClickPhoneNumber(phoneString);
            } else {
              // toast.show({
              //   description: "Ghost Calling only available on BlazeRelay mobile",
              //   placement: "top",
              // });
            }
          }}
        >
          <Text color={"blue.600"}>{phoneString}</Text>
        </Pressable>
      );

      // console.log("ttp", splitText[1]);
      textToProcess = splitText[1];
    } else {
      textToProcess = "";
    }
  }

  textComponents.push(textToProcess?.replace("PN<", "")?.replace(">/PN", ""));

  return (
    <Text my={"1"}>
      {textComponents.map((component) => {
        return component;
      })}
    </Text>
  );
};

const MessageCard = ({
  item,
  checkMessages,
  openModal,
  openForwardModal,
  nav,
}) => {
  const [isToggled, setIsToggled] = React.useState(false);
  const toast = useToast();
  const store = React.useContext(StoreContext);
  const [notes, setNotes] = React.useState([]);

  const handleClickPhoneNumber = (phoneNumber) => {
    console.log(phoneNumber);

    store.ghostCallToNumber = phoneNumber.replace("+1", "");
    nav.navigate("GhostCallScreen");
  };
  return (
    <Pressable
      onPress={() => {
        if (!isToggled) {
          fetchAPIJSON("api/v1/blaze/retMessageNotes", store, nav, {
            message_id: item.message_id,
          }).then((res) => {
            setNotes(res.data.messageNotes);
            // setShowLoadingSpinner(false);
          });
        }
        setIsToggled(!isToggled);
      }}
    >
      <VStack
        my={"2"}
        py={"4"}
        px={"6"}
        borderRadius="lg"
        borderColor={"warmGray.100"}
        borderWidth={"1"}
        bg={"white"}
        shadow={"2"}
        justifyContent={"center"}
      >
        <Text
          fontSize={"lg"}
          fontWeight={"semibold"}
          color={"primary.700"}
          mb={"2"}
        >
          {new Date(item.time).toLocaleString(navigator.language, {
            hour: "2-digit",
            minute: "2-digit",
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            timeZoneName: "short",
          })}
        </Text>
        <VStack>
          {createMessageText(item.message, handleClickPhoneNumber)}
        </VStack>

        {isToggled && (
          <VStack>
            {notes?.length > 0 &&
              notes.map(({ system_message, note, time_created, note_id }) => {
                return (
                  <MessageNote
                    isSystem={system_message}
                    note={note}
                    timestamp={time_created}
                    key={note_id}
                  ></MessageNote>
                );
              })}

            <HStack mt={"4"}>
              {!item.confirmed && (
                <Pressable
                  onPress={() => {
                    console.log("message id", item.message_id);
                    fetchAPIJSON("api/v1/blaze/confirmMessage", store, nav, {
                      message_id: item.message_id,
                    })
                      .then((res) => {
                        checkMessages();
                        toast.show({
                          description: "Message Confirmed",
                          placement: "top",
                        });
                        if (Platform.OS != "web") {
                          // Notifications.getBadgeCountAsync().then((res) => {
                          //   console.log("Badge: ", res);
                          // });
                          // Notifications.dismissAllNotificationsAsync();
                          // Notifications.setBadgeCountAsync(0);
                        }
                      })
                      .catch((err) => {
                        toast.show({
                          placement: "top",
                          render: () => {
                            return (
                              <Box
                                bg="red.500"
                                px="2"
                                py="1"
                                rounded="sm"
                                mb={5}
                              >
                                Failed to confirm message.
                              </Box>
                            );
                          },
                        });
                        console.log("bad", err);
                      });
                  }}
                >
                  <Icon
                    as={MaterialIcons}
                    name="check"
                    size="8"
                    color={"green.600"}
                  />
                </Pressable>
              )}
              {item.confirmed && (
                <Pressable
                  onPress={() => {
                    fetchAPIJSON("api/v1/blaze/deleteMessage", store, nav, {
                      message_id: item.message_id,
                    }).then((res) => {
                      checkMessages();
                      toast.show({
                        description: "Message Cleared",
                        placement: "top",
                      });
                    });
                  }}
                >
                  <Icon
                    as={MaterialIcons}
                    name="delete-outline"
                    size="8"
                    color={"red.600"}
                  />
                </Pressable>
              )}

              <Pressable
                ml="5"
                onPress={() => {
                  openModal(item.acc_sent_message_id);
                }}
              >
                <Icon
                  as={MaterialIcons}
                  name="reply"
                  size="8"
                  color={"blue.600"}
                />
              </Pressable>

              <Pressable
                ml="5"
                onPress={() => {
                  openForwardModal(item.acc_id, item.message_id);
                }}
              >
                <Icon
                  as={MaterialIcons}
                  name="forward-to-inbox"
                  size="8"
                  color={"yellow.600"}
                />
              </Pressable>
            </HStack>
          </VStack>
        )}
      </VStack>
    </Pressable>
  );
};

const ReplyToMessageModal = ({
  acc_sent_message_id,
  navigation,
  closeModal,
}) => {
  const [replyText, setReplyText] = React.useState("");
  const store = React.useContext(StoreContext);
  const toast = useToast();

  const replyToMessage = () => {
    // setModalVisible(false);
    closeModal();
    return new Promise((resolve, reject) => {
      console.log("Sending", replyText);
      fetchAPIJSON("api/v1/blaze/blazeReplyToMessage", store, navigation, {
        response_text: replyText,
        acc_sent_message_id: acc_sent_message_id,
      })
        .then((res) => {
          setReplyText("");
          toast.show({
            description: "Reply Sent to Agent",
            placement: "top",
          });
          resolve();
        })
        .catch(() => {
          toast.show({
            placement: "top",
            render: () => {
              return (
                <Box bg="red.500" px="2" py="1" rounded="sm" mb={5}>
                  Failed to reply to message.
                </Box>
              );
            },
          });
          reject();
        });
    });
  };
  return (
    <Modal isOpen={true} onClose={() => closeModal()} avoidKeyboard size="lg">
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header>Reply To Message</Modal.Header>
        <Modal.Body>
          Add a response below to be sent to an agent.
          <FormControl mt="3">
            <FormControl.Label>Response</FormControl.Label>
            <Input
              value={replyText}
              onChangeText={(txt) => setReplyText(txt)}
            />
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          <Button
            flex="1"
            onPress={() => {
              replyToMessage();
            }}
          >
            Send
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

const SendNewMessageModal = ({
  accList,
  navigation,
  checkMessages,
  closeModal,
}) => {
  const [newMessageText, setNewMessageText] = React.useState("");
  const [selectedAcc, setSelectedAcc] = React.useState("");
  const store = React.useContext(StoreContext);
  const toast = useToast();

  const sendNewMessage = () => {
    // setNewMessageModalVisible(false);
    return new Promise((resolve, reject) => {
      console.log("Sending", newMessageText);
      fetchAPIJSON("api/v1/blaze/sendMessageToAgent", store, navigation, {
        response_text: newMessageText,
        acc_id: selectedAcc,
      })
        .then((res) => {
          setNewMessageText("");
          checkMessages();
          toast.show({
            description: "Message Sent to Agent",
            placement: "top",
          });
          closeModal();
          resolve();
        })
        .catch((err) => {
          toast.show({
            placement: "top",
            render: () => {
              return (
                <Box bg="red.500" px="2" py="1" rounded="sm" mb={5}>
                  Failed to send message.
                </Box>
              );
            },
          });
          console.log("err", err);
        });
    });
  };

  return (
    <Modal isOpen={true} onClose={() => closeModal()} avoidKeyboard size="lg">
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header>New Message</Modal.Header>
        <Modal.Body>
          {accList?.length > 1 && (
            <VStack>
              <Text>Account to send a message from:</Text>

              <Select
                selectedValue={selectedAcc}
                minWidth="200"
                accessibilityLabel="Select Account"
                placeholder="Select Account"
                _selectedItem={{
                  bg: "primary.600",
                }}
                mt={1}
                mb={4}
                onValueChange={(itemValue) => setSelectedAcc(itemValue)}
              >
                {accList?.length > 0 &&
                  accList.map(({ acc_id, acc_name }) => {
                    return (
                      <Select.Item
                        key={acc_id}
                        label={acc_name}
                        value={acc_id}
                      />
                    );
                  })}
              </Select>
            </VStack>
          )}
          <Text>Type a message to be sent to an agent.</Text>

          <FormControl>
            <FormControl.Label>Your message</FormControl.Label>
            <Input
              value={newMessageText}
              onChangeText={(txt) => setNewMessageText(txt)}
            />
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          <Button
            flex="1"
            onPress={() => {
              sendNewMessage();
            }}
          >
            Send
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default function HomeScreen(props) {
  const store = React.useContext(StoreContext);
  const appState = React.useRef(AppState.currentState);
  const toast = useToast();

  const [messages, setMessages] = React.useState([]);

  const [modalVisible, setModalVisible] = React.useState(false);
  const [currentReplyAccSentMessageId, setCurrentReplyAccSentMessageId] =
    React.useState("");

  const [newMessageModalVisible, setNewMessageModalVisible] =
    React.useState(false);
  const [newMessageText, setNewMessageText] = React.useState("");
  const [accList, setAccList] = React.useState(["Loading Accounts"]);
  const [selectedAcc, setSelectedAcc] = React.useState("");

  const [contacts, setContacts] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState("");
  const [forwardingMessageId, setForwardingMessageId] = React.useState("");
  const [forwardModalVisible, setForwardModalVisible] = React.useState(false);

  const [showLoadingSpinner, setShowLoadingSpinner] = React.useState(false);

  const checkMessages = () => {
    return new Promise((resolve, reject) => {
      setMessages([]);
      setShowLoadingSpinner(true);
      fetchAPIJSON("api/v1/blaze/retInboxMessages", store, props.navigation)
        .then((res) => {
          if (res.data.messages != undefined) {
            setMessages(res.data.messages);
          }
          setShowLoadingSpinner(false);
          resolve();
        })
        .catch(() => {
          // toast.show({
          //   placement: "top",
          //   render: () => {
          //     return (
          //       <Box bg="red.500" px="2" py="1" rounded="sm" mb={5}>
          //         Failed to get messages
          //       </Box>
          //     );
          //   },
          // });
          reject();
        });
    });
  };

  const getContactsMatchingAcc = (acc_id) => {
    return new Promise((resolve, reject) => {
      setContacts([]);
      fetchAPIJSON(
        "api/v1/blaze/retSameAccClientUsers",
        store,
        props.navigation,
        {
          acc_id: acc_id,
        }
      )
        .then((res) => {
          setContacts(res.data.users);
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  };

  const handleClickReply = (acc_sent_message_id) => {
    setCurrentReplyAccSentMessageId(acc_sent_message_id);
    setModalVisible(true);
  };

  const handleClickForward = (acc_id, message_id) => {
    getContactsMatchingAcc(acc_id);
    setForwardingMessageId(message_id);
    setForwardModalVisible(true);
  };

  const forwardMesasge = () => {
    setForwardModalVisible(false);
    return new Promise((resolve, reject) => {
      console.log("forwarding", selectedUser, forwardingMessageId);
      fetchAPIJSON("api/v1/blaze/forwardMessage", store, props.navigation, {
        newClientUserId: selectedUser,
        message_id: forwardingMessageId,
      })
        .then((res) => {
          setSelectedUser("");
          toast.show({
            description: "Message Forwarded",
            placement: "top",
          });
          resolve();
        })
        .catch((err) => {
          toast.show({
            placement: "top",
            render: () => {
              return (
                <Box bg="red.500" px="2" py="1" rounded="sm" mb={5}>
                  Failed to forward message.
                </Box>
              );
            },
          });
          console.log("forwarding error", err);
        });
    });
  };

  // React.useEffect(() => {
  //   checkMessages();
  // }, []);
  React.useEffect(() => {
    console.log("u2");
    const unsubscribe = props.navigation.addListener("focus", () => {
      const accs = store.accs_array;
      setAccList(accs);
      if (accs.length == 1) {
        setSelectedAcc(accs[0]?.acc_id);
      }
      checkMessages();
    });

    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe;
  }, [props.navigation]);

  React.useEffect(() => {
    console.log("u3");
    const subscription = AppState.addEventListener("change", (nextAppState) => {
      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === "active"
      ) {
        const accs = store.accs_array;
        setAccList(accs);
        if (accs.length == 1) {
          setSelectedAcc(accs[0]?.acc_id);
        }
        checkMessages();
      }

      appState.current = nextAppState;
      // setAppStateVisible(appState.current);
      console.log("AppState", appState.current);
    });

    return () => {
      subscription.remove();
    };
  }, []);

  return (
    <Observer>
      {() => (
        <>
          <DashboardLayout
            title=""
            displayMenuButton={false}
            displayScreenTitle={false}
            displayAlternateMobileHeader={false}
            nav={props.navigation}
          >
            <ScrollView>
              <VStack
                zIndex={2}
                borderRadius={{ md: "8" }}
                p={{ base: 4, md: 6 }}
                mb={{ md: "4" }}
              ></VStack>

              <VStack
                px={{ base: 4, md: 6 }}
                pt={{ base: 4, md: 6 }}
                pb={{ base: 24, md: 6 }}
              >
                <HStack justifyContent={"flex-end"}>
                  {store.accessAllMessages && Platform.OS != "web" && (
                    <Pressable
                      mr="5"
                      onPress={() => {
                        props.navigation.navigate("AllMessagesScreen");
                      }}
                    >
                      <Icon
                        as={MaterialIcons}
                        name="list"
                        size="7"
                        color={"green.600"}
                      />
                    </Pressable>
                  )}

                  <Pressable
                    mr="3"
                    onPress={() => {
                      setNewMessageModalVisible(true);
                    }}
                  >
                    <Icon
                      as={MaterialIcons}
                      name="create"
                      size="7"
                      color={"green.600"}
                    />
                  </Pressable>
                </HStack>

                <VStack borderRadius="lg" mt={{ base: "4", md: "1" }}>
                  <Text
                    _dark={{ color: "coolGray.50" }}
                    _light={{ color: "coolGray.800" }}
                    fontSize="2xl"
                    fontWeight="bold"
                  >
                    New Messages
                  </Text>
                  {showLoadingSpinner && (
                    <HStack space={2} my={4} justifyContent="center">
                      <Spinner />
                      <Heading color="primary.500" fontSize="md">
                        Loading Messages
                      </Heading>
                    </HStack>
                  )}
                  {/* {console.log("messages", messages.length, messages)} */}
                  {messages.length > 0 &&
                    messages.map(
                      ({
                        message_id,
                        message,
                        time_created,
                        confirmed_app,
                        acc_sent_message_id,
                        acc_id,
                      }) => {
                        if (!confirmed_app) {
                          return (
                            <MessageCard
                              key={message_id}
                              item={{
                                message,
                                time: time_created,
                                message_id,
                                acc_sent_message_id,
                                confirmed: false,
                                acc_id,
                              }}
                              checkMessages={checkMessages}
                              openModal={handleClickReply}
                              openForwardModal={handleClickForward}
                              nav={props.navigation}
                            ></MessageCard>
                          );
                        }
                      }
                    )}
                </VStack>
                <VStack borderRadius="lg" mt={"12"}>
                  <Text
                    _dark={{ color: "coolGray.50" }}
                    _light={{ color: "coolGray.800" }}
                    fontSize="2xl"
                    fontWeight="bold"
                  >
                    Previous Messages
                  </Text>
                  {messages.length > 0 &&
                    messages.map(
                      ({
                        message_id,
                        message,
                        time_created,
                        confirmed_app,
                        acc_sent_message_id,
                        acc_id,
                      }) => {
                        if (confirmed_app) {
                          return (
                            <MessageCard
                              key={message_id}
                              item={{
                                message,
                                time: time_created,
                                message_id,
                                acc_sent_message_id,
                                confirmed: true,
                                acc_id,
                              }}
                              checkMessages={checkMessages}
                              openModal={handleClickReply}
                              openForwardModal={handleClickForward}
                              nav={props.navigation}
                            ></MessageCard>
                          );
                        }
                      }
                    )}
                </VStack>
              </VStack>
            </ScrollView>
            {forwardModalVisible && (
              <Modal
                isOpen={forwardModalVisible}
                onClose={() => setForwardModalVisible(false)}
                avoidKeyboard
                size="lg"
              >
                <Modal.Content>
                  <Modal.CloseButton />
                  <Modal.Header>Forward Message</Modal.Header>
                  <Modal.Body>
                    <Text>Select a user to forward to</Text>

                    <Select
                      selectedValue={selectedUser}
                      minWidth="200"
                      accessibilityLabel="Select User"
                      placeholder="Select User"
                      _selectedItem={{
                        bg: "primary.600",
                      }}
                      mt={1}
                      mb={4}
                      onValueChange={(itemValue) => setSelectedUser(itemValue)}
                    >
                      {contacts?.length > 0 &&
                        contacts.map(
                          ({ client_user_id, first_name, last_name }) => {
                            const name = first_name + " " + last_name;
                            return (
                              <Select.Item
                                key={client_user_id}
                                label={name}
                                value={client_user_id}
                              />
                            );
                          }
                        )}
                    </Select>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      flex="1"
                      onPress={() => {
                        forwardMesasge();
                      }}
                    >
                      Send
                    </Button>
                  </Modal.Footer>
                </Modal.Content>
              </Modal>
            )}
            {newMessageModalVisible && (
              <SendNewMessageModal
                accList={accList}
                navigation={props.navigation}
                checkMessages={() => {
                  checkMessages();
                }}
                closeModal={() => {
                  setNewMessageModalVisible(false);
                }}
              ></SendNewMessageModal>
            )}
            {modalVisible && (
              <ReplyToMessageModal
                acc_sent_message_id={currentReplyAccSentMessageId}
                navigation={props.navigation}
                closeModal={() => {
                  setModalVisible(false);
                }}
              />
            )}
          </DashboardLayout>
          <MobileFooter nav={props.navigation} />
        </>
      )}
    </Observer>
  );
}
