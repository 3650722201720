import React from "react";
import { Observer } from "mobx-react";
import {
  Box,
  VStack,
  ScrollView,
  HStack,
  Icon,
  Text,
  Divider,
  Avatar,
  Modal,
  Button,
} from "native-base";
import { MaterialIcons, Ionicons } from "@expo/vector-icons";
import { StoreContext } from "../utilities/store";

export default function Sidebar(props) {
  const store = React.useContext(StoreContext);

  const [logoutOpen, setLogoutOpen] = React.useState(false);
  const list = [
    {
      iconName: "message",
      iconText: "Your Messages",
      navName: "HomeScreen",
      viewable: true,
    },
    {
      iconName: "list",
      iconText: "All Messages",
      navName: "AllMessagesScreen",
      viewable: store.accessAllMessages,
    },
    {
      iconName: "perm-contact-calendar",
      iconText: "On Call",
      navName: "OnCallScreen",
      viewable: store.accessOnCall,
    },
    {
      iconName: "multitrack-audio",
      iconText: "Call Recordings",
      navName: "CallRecordingsScreen",
      viewable: store.accessCallRecordings,
    },
    {
      iconName: "view-list",
      iconText: "Data Tables",
      navName: "DataTablesScreen",
      viewable: store.accessDatatables,
    },
  ];
  return (
    <Observer>
      {() => (
        <Box
          w="80"
          borderRightWidth="1"
          display="flex"
          _light={{ bg: "white", borderRightColor: "coolGray.200" }}
          _dark={{ bg: "coolGray.900", borderRightColor: "coolGray.800" }}
        >
          <ScrollView>
            <VStack
              pb="4"
              mt="5"
              space="3"
              alignItems="center"
              borderBottomWidth="1"
              _light={{
                borderBottomColor: "coolGray.200",
              }}
              _dark={{
                borderBottomColor: "coolGray.800",
              }}
            >
              <Ionicons name="person-circle-sharp" size={100} color="#c7e6ad" />
              <HStack alignItems="center" justifyContent="center" space="2">
                <Text
                  fontSize="xl"
                  fontWeight="bold"
                  _light={{ color: "coolGray.800" }}
                >
                  {store.userFirstName} {store.userLastName}
                </Text>
              </HStack>
              <Text
                fontSize="md"
                fontWeight="medium"
                textAlign="center"
                _light={{ color: "coolGray.500" }}
              >
                {store.userEmail}
              </Text>
            </VStack>
            <VStack px="4" py="4">
              {list.map((item, idx) => {
                if (item.viewable == true) {
                  return (
                    <Button
                      key={idx}
                      variant="ghost"
                      justifyContent="flex-start"
                      onPress={() => {
                        props.nav.navigate(item.navName);
                      }}
                      py="4"
                      px="5"
                    >
                      <HStack space="4" alignItems="center">
                        <Icon
                          size="6"
                          as={MaterialIcons}
                          name={item.iconName}
                          _dark={{ color: "coolGray.50" }}
                          _light={{ color: "coolGray.500" }}
                        />
                        <Text
                          fontSize="md"
                          fontWeight="medium"
                          _dark={{ color: "coolGray.50" }}
                          _light={{ color: "coolGray.800" }}
                        >
                          {item.iconText}
                        </Text>
                      </HStack>
                    </Button>
                  );
                }
              })}
            </VStack>
            <Divider _dark={{ bgColor: "coolGray.800" }} />
            <Box px="4" py="2">
              <Button
                variant="ghost"
                justifyContent="flex-start"
                py="4"
                px="5"
                onPress={() => {
                  // store.signOut();
                  // props.nav.navigate("Splash");
                  setLogoutOpen(true);
                }}
              >
                <Modal
                  isOpen={logoutOpen}
                  onClose={() => setLogoutOpen(false)}
                  avoidKeyboard
                  size="full"
                  w={{ md: "40%" }}
                  alignSelf={"center"}
                >
                  {/* tableRows: tableRows, table_name: table_name, hash: hash,
                columns: columns, acc_id: acc_id, */}
                  <Modal.Content>
                    {/* <Modal.CloseButton /> */}
                    <Modal.Header>Confirm Logout</Modal.Header>
                    <Modal.Body>
                      <Text fontSize={"lg"}>
                        Are you sure you want to logout?
                      </Text>
                      <Text fontSize={"md"} mt={2}>
                        You'll need to re-enter your password next time you sign
                        in.
                      </Text>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        flex="1"
                        mx={2}
                        onPress={() => {
                          setLogoutOpen(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        flex="1"
                        mx={2}
                        onPress={() => {
                          setLogoutOpen(false);
                          store.signOut();
                          props.nav.navigate("Splash");
                        }}
                      >
                        Logout
                      </Button>
                    </Modal.Footer>
                  </Modal.Content>
                </Modal>
                <HStack space="4" alignItems="center">
                  <Icon
                    size="6"
                    as={MaterialIcons}
                    name="exit-to-app"
                    _dark={{ color: "coolGray.50" }}
                    _light={{ color: "coolGray.500" }}
                  />
                  <Text
                    fontSize="md"
                    fontWeight="medium"
                    _dark={{ color: "coolGray.50" }}
                    _light={{ color: "coolGray.800" }}
                  >
                    Logout
                  </Text>
                </HStack>
              </Button>
            </Box>
          </ScrollView>
        </Box>
      )}
    </Observer>
  );
}
