const baseURL = "https://production-backend.ringflare.science/";
// const baseURL = "http://10.0.2.2:3000/";
// // const baseURL = "http://localhost:3000/";

const authApiVersionURL = "auth/v1/";

const authLogin = (email, password) => {
  return new Promise((resolve, reject) => {
    const fullURL = `${baseURL}${authApiVersionURL}authenticateClientUser`;
    fetch(fullURL, {
      headers: {
        "content-type": " application/json",
      },
      method: "POST",
      body: JSON.stringify({
        email,
        password,
      }),
    })
      .then((res) => {
        if (res.status == 200 || res.status == 500) {
          res.json().then((jsonData) => {
            console.log(res.status);
            if (res.status != 200) {
              reject(jsonData);
            } else {
              resolve(jsonData);
            }
          });
        } else {
          console.log(res.status);
          console.log("bad");
          reject({});
        }
      })
      .catch((err) => {
        reject({});
        console.log(err);
      });
  });
};

const checkSetupCode = (setupCode) => {
  return new Promise((resolve, reject) => {
    const fullURL = `${baseURL}${authApiVersionURL}blaze/getSetupCodeData`;
    fetch(fullURL, {
      headers: {
        "content-type": " application/json",
      },
      method: "POST",
      body: JSON.stringify({
        setupCode,
      }),
    })
      .then((res) => {
        if (res.status == 200 || res.status == 500) {
          res.json().then((jsonData) => {
            console.log(res.status);
            if (res.status != 200) {
              reject({});
            } else {
              if (jsonData.statusCode == "SP100") {
                resolve(jsonData);
              } else {
                console.log("statusCode", jsonData.statusCode);
                reject(jsonData);
              }
            }
          });
        } else {
          console.log(res.status);
          console.log("bad");
          reject({});
        }
      })
      .catch((err) => {
        reject({});
        console.log("err", err);
      });
  });
};

const resetPassword = (email) => {
  return new Promise((resolve, reject) => {
    const lowercaseEmail = email.toLowerCase();
    const fullURL = `${baseURL}${authApiVersionURL}blaze/requestPasswordReset`;

    fetch(fullURL, {
      headers: {
        "content-type": " application/json",
      },
      method: "POST",
      body: JSON.stringify({
        email: lowercaseEmail,
      }),
    })
      .then((res) => {
        if (res.status == 200 || res.status == 500) {
          res.json().then((jsonData) => {
            console.log(res.status);
            if (res.status != 200) {
              reject(jsonData);
            } else {
              if (jsonData.statusCode == "SP100") {
                resolve(jsonData);
              } else {
                console.log("Finish ", jsonData.statusCode);
                reject(jsonData);
              }
            }
          });
        } else {
          console.log(res.status);
          console.log("bad");
          reject({});
        }
      })
      .catch((err) => {
        reject({});
        console.log(err);
      });
  });
};

const finishSetup = (setupCode, email, password, org_id) => {
  return new Promise((resolve, reject) => {
    const upperCaseSetupCode = setupCode.toUpperCase();
    const fullURL = `${baseURL}${authApiVersionURL}blaze/finishAccUserSetup`;
    fetch(fullURL, {
      headers: {
        "content-type": " application/json",
      },
      method: "POST",
      body: JSON.stringify({
        setupCode: upperCaseSetupCode,
        email,
        password,
        org_id,
      }),
    })
      .then((res) => {
        if (res.status == 200 || res.status == 500) {
          res.json().then((jsonData) => {
            console.log(res.status);
            if (res.status != 200) {
              reject(jsonData);
            } else {
              if (jsonData.statusCode == "SP100") {
                resolve(jsonData);
              } else {
                console.log("Finish ", jsonData.statusCode);
                reject(jsonData);
              }
            }
          });
        } else {
          console.log(res.status);
          console.log("bad");
          reject({});
        }
      })
      .catch((err) => {
        reject({});
        console.log(err);
      });
  });
};

const reportLoginFlowDebug = (isWarning, position, debugInfo) => {
  fetch(`${baseURL}auth/v1/blaze/reportLoginFlow`, {
    headers: {
      "content-type": " application/json",
    },
    method: "POST",
    body: JSON.stringify({
      warning: isWarning,
      debug: {
        info: debugInfo,
        position: position,
      },
    }),
  })
    .then((res) => {
      console.log("Logged Debug");
    })
    .catch((err) => {
      console.log("Failed to Log Debug", err);
    });
};

const reportDeleteAcc = (email, first_name, last_name) => {
  fetch(`${baseURL}auth/v1/blaze/reportLoginFlow`, {
    headers: {
      "content-type": " application/json",
    },
    method: "POST",
    body: JSON.stringify({
      warning: true,
      debug: {
        info: `User requested account deletion. User info: ${email} - ${first_name} ${last_name}`,
        position: "deleteAccount",
      },
    }),
  })
    .then((res) => {
      console.log("Logged Debug");
    })
    .catch((err) => {
      console.log("Failed to Log Debug", err);
    });
};

const fetchAPIJSON = (
  endpoint,
  mobxStore,
  nav,
  bodyJSON = {},
  customToastHandler = {}
) => {
  return new Promise((resolve, reject) => {
    const authHeader = mobxStore.authHeader;
    if (authHeader == null || authHeader.length < 2) {
      reject({});
    } else {
      console.log("go");
      fetch(`${baseURL}${endpoint}`, {
        headers: {
          "content-type": " application/json",
          authorization: authHeader,
        },
        method: "POST",
        body: JSON.stringify(bodyJSON),
      }).then(async (res) => {
        if (res.status != 401) {
          res
            .json()
            .then((jsonData) => {
              // console.log("jsondata", jsonData);
              // !! Need to handle accessTokens bc right now cant edit store outside of a component
              if (jsonData.newAccessToken) {
                console.log("New Auth Token!");
                mobxStore.accessToken = jsonData.newAccessToken;
              }
              const status = jsonData.statusCode;
              if (customToastHandler[status]) {
                //handle special status notif
                resolve(jsonData);
              } else {
                switch (status) {
                  case "SP100":
                    resolve(jsonData);
                    break;
                  case "SP105":
                    resolve(jsonData);
                    break;
                  case "SP700":
                    //handle logout
                    reject(jsonData);
                    break;
                  default:
                    reject(jsonData);
                }
              }
            })
            .catch((err) => {
              console.log("jsonerr", err);
              console.log("failed res", res);
            });
        } else {
          mobxStore.clear();
          nav.navigate("Splash");
          console.log("401", await res.text());
          console.log("401", authHeader);
          reject({});
        }
      });
    }
  });
};

export {
  authLogin,
  fetchAPIJSON,
  checkSetupCode,
  finishSetup,
  resetPassword,
  reportLoginFlowDebug,
  reportDeleteAcc,
};
