import React, { useState, useEffect, useMemo } from "react";
import { Observer, useObserver } from "mobx-react";
import { MaterialIcons } from "@expo/vector-icons";

import "intl";
import "intl/locale-data/jsonp/en";

import {
  Box,
  HStack,
  Icon,
  Text,
  VStack,
  Button,
  Avatar,
  Image,
  ScrollView,
  Pressable,
  Hidden,
  Select,
  Center,
  IconButton,
  ArrowBackIcon,
  FormControl,
  Modal,
  Input,
  Checkbox,
  useToast,
  Spinner,
  Heading,
  Badge,
  AlertDialog,
} from "native-base";

import { StoreContext } from "../../utilities/store";
import DashboardLayout from "../../layouts/DashboardLayout";
import { fetchAPIJSON } from "../../utilities/network";

function MobileFooter(props) {
  const store = props.store;

  return (
    <Hidden from="md">
      <HStack
        justifyContent="space-between"
        safeAreaBottom
        h="20"
        width="100%"
        overflow="hidden"
        borderTopRightRadius={"xl"}
        borderTopLeftRadius={"xl"}
        _light={{ backgroundColor: "primary.600" }}
        _dark={{ backgroundColor: "coolGray.800" }}
      >
        <Button
          variant="ghost"
          flex={1}
          colorScheme="coolGray"
          _stack={{
            flexDirection: "column",
          }}
          startIcon={
            <Icon as={MaterialIcons} name="message" size="5" color="white" />
          }
          onPress={() => {
            props.nav.navigate("HomeScreen");
          }}
        ></Button>
        {store.accessOnCall && (
          <Button
            variant="ghost"
            flex={1}
            _stack={{
              flexDirection: "column",
            }}
            colorScheme="coolGray"
            startIcon={
              <Icon as={MaterialIcons} name="perm-contact-calendar" size="5" />
            }
            onPress={() => {
              props.nav.navigate("OnCallScreen");
            }}
          ></Button>
        )}
        {store.accessGhostCall && (
          <Button
            variant="ghost"
            flex={1}
            colorScheme="coolGray"
            _stack={{
              flexDirection: "column",
            }}
            startIcon={
              <Icon as={MaterialIcons} name="phone-in-talk" size="5" />
            }
            onPress={() => {
              props.nav.navigate("GhostCallScreen");
            }}
          ></Button>
        )}
        {store.accessCallRecordings && (
          <Button
            variant="ghost"
            flex={1}
            colorScheme="coolGray"
            _stack={{
              flexDirection: "column",
            }}
            startIcon={
              <Icon as={MaterialIcons} name="multitrack-audio" size="5" />
            }
            onPress={() => {
              props.nav.navigate("CallRecordingsScreen");
            }}
          ></Button>
        )}
        {store.accessDatatables && (
          <Button
            variant="ghost"
            flex={1}
            colorScheme="coolGray"
            _stack={{
              flexDirection: "column",
            }}
            startIcon={<Icon as={MaterialIcons} name="view-list" size="5" />}
            onPress={() => {
              props.nav.navigate("DataTablesScreen");
            }}
          ></Button>
        )}
        <Button
          variant="ghost"
          flex={1}
          colorScheme="coolGray"
          _stack={{
            flexDirection: "column",
          }}
          onPress={() => {
            props.nav.navigate("SettingsScreen");
          }}
          startIcon={<Icon as={MaterialIcons} name="settings" size="5" />}
        ></Button>
      </HStack>
    </Hidden>
  );
}

const MessageNote = ({ isSystem, note, timestamp, accTimezone }) => {
  return (
    <VStack mt={4}>
      <HStack>
        {isSystem && (
          <Badge colorScheme="info" variant={"solid"}>
            System
          </Badge>
        )}
        {!isSystem && (
          <Badge colorScheme="warning" variant={"solid"}>
            Agent
          </Badge>
        )}
        <Text>
          {" "}
          {new Date(timestamp).toLocaleString(navigator.language, {
            hour: "2-digit",
            minute: "2-digit",
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
            timeZone: accTimezone,
            timeZoneName: "short",
          })}
        </Text>
      </HStack>
      <Text ml={4}>{note}</Text>
    </VStack>
  );
};

const MessageCard = ({
  title,
  time_created,
  timezone,
  qdoc_response_id,
  sent_message_id,
  acc_id,
  navigation,
}) => {
  const [isToggled, setIsToggled] = React.useState(false);
  const toast = useToast();
  const store = React.useContext(StoreContext);
  const [notes, setNotes] = React.useState([]);
  const [messageText, setMessageText] = React.useState("");
  const [showLoadingSpinner, setShowLoadingSpinner] = React.useState(false);

  const handleClickPhoneNumber = (phoneNumber) => {
    console.log(phoneNumber);

    store.ghostCallToNumber = phoneNumber.replace("+1", "");
    navigation.navigate("GhostCallScreen");
  };

  const getMessageDetail = () => {
    return new Promise((resolve, reject) => {
      setMessageText("");
      setNotes([]);
      setShowLoadingSpinner(true);
      fetchAPIJSON("api/v1/blaze/retMessageInfo", store, navigation, {
        acc_id: acc_id,
        sent_message_id: sent_message_id,
        qdoc_response_id: qdoc_response_id,
      })
        .then((res) => {
          if (res.data?.message != undefined) {
            setMessageText(res.data?.message);
          }
          if (res.data?.accSentMessageNotes != undefined) {
            console.log("notes", res.data?.accSentMessageNotes);
            setNotes(res.data?.accSentMessageNotes);
          }
          setShowLoadingSpinner(false);
          console.log("detail res", res);
        })
        .catch((err) => {
          setShowLoadingSpinner(false);
          reject(err);
        });
    });
  };

  return (
    <Pressable
      onPress={() => {
        if (!isToggled) {
          //do open logic here
          getMessageDetail();
        }
        setIsToggled(!isToggled);
      }}
    >
      <VStack
        my={"2"}
        py={"4"}
        px={"6"}
        borderRadius="lg"
        borderColor={"warmGray.100"}
        borderWidth={"1"}
        bg={"white"}
        shadow={"2"}
        justifyContent={"center"}
      >
        <Text
          fontSize={"md"}
          fontWeight={"semibold"}
          color={"primary.700"}
          mb={"2"}
        >
          {new Date(time_created).toLocaleString(navigator.language, {
            hour: "2-digit",
            minute: "2-digit",
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
            timeZone: timezone,
            timeZoneName: "short",
          })}
        </Text>
        <VStack>
          <Text fontSize={"md"}>{title}</Text>
        </VStack>
        {isToggled && showLoadingSpinner && (
          <HStack space={2} my={4} justifyContent="center">
            <Spinner />
            <Heading color="primary.500" fontSize="md">
              Loading Message Content
            </Heading>
          </HStack>
        )}
        {isToggled && (
          <VStack>
            {createMessageText(messageText, handleClickPhoneNumber)}
          </VStack>
        )}
        {isToggled && (
          <VStack>
            {notes?.length > 0 &&
              notes.map(({ system_message, note, time_created, note_id }) => {
                return (
                  <MessageNote
                    isSystem={system_message}
                    note={note}
                    timestamp={time_created}
                    key={note_id}
                    accTimezone={timezone}
                  ></MessageNote>
                );
              })}

            {/* <HStack mt={"4"}>
              <Pressable ml="5" onPress={() => {}}>
                <Icon
                  as={MaterialIcons}
                  name="reply"
                  size="8"
                  color={"blue.600"}
                />
              </Pressable>

              <Pressable ml="5" onPress={() => {}}>
                <Icon
                  as={MaterialIcons}
                  name="forward-to-inbox"
                  size="8"
                  color={"yellow.600"}
                />
              </Pressable>
            </HStack> */}
          </VStack>
        )}
      </VStack>
    </Pressable>
  );
};

const createMessageText = (text, onClickPhoneNumber) => {
  let textToProcess = text;
  let textComponents = [];

  while (textToProcess?.includes("PN<")) {
    const numberTest = textToProcess.match(new RegExp("PN<" + "(.*)" + ">/PN"));

    if (numberTest != null) {
      // console.log("numTest", numberTest.length);
      const phoneString = numberTest[0].replace("PN<", "").replace(">/PN", "");
      let splitText = textToProcess.split(phoneString);
      // console.log("splitting on", phoneString);

      //this stops text from breaking if the same PN appears twice in one message
      if (splitText.length > 2) {
        let tempArray = [...splitText].slice(1);

        tempArray = tempArray.join(phoneString);
        splitText[1] = tempArray;
      }

      textComponents.push(
        <Text>{splitText[0].replace("PN<", "").replace(">/PN", "")}</Text>
      );
      textComponents.push(
        <Pressable
          // backgroundColor={"pink.400"}
          onPress={() => {
            if (Platform.OS != "web") {
              onClickPhoneNumber(phoneString);
            } else {
              // toast.show({
              //   description: "Ghost Calling only available on BlazeRelay mobile",
              //   placement: "top",
              // });
            }
          }}
        >
          <Text color={"blue.600"}>{phoneString}</Text>
        </Pressable>
      );

      // console.log("ttp", splitText[1]);
      textToProcess = splitText[1];
    } else {
      textToProcess = "";
    }
  }

  textComponents.push(textToProcess?.replace("PN<", "")?.replace(">/PN", ""));

  return (
    <Text my={"1"}>
      {textComponents.map((component) => {
        return component;
      })}
    </Text>
  );
};

export default function AllMessagesScreen(props) {
  const store = React.useContext(StoreContext);
  const toast = useToast();

  const [showLoadingSpinner, setShowLoadingSpinner] = React.useState(false);
  const [messages, setMessages] = React.useState([]);
  const [selectedAccount, setSelectedAccount] = React.useState(undefined);
  const [accList, setAccList] = useState(["Loading Accounts"]);

  const getAllMessages = (acc_id) => {
    return new Promise((resolve, reject) => {
      if (acc_id == undefined) {
        reject("undefined");
      }
      console.log("acc", selectedAccount);
      setMessages([]);
      setShowLoadingSpinner(true);
      fetchAPIJSON("api/v1/blaze/retAllAccMessages", store, props.navigation, {
        acc_id: acc_id,
      })
        .then((res) => {
          console.log("msg res", res);
          if (res.data?.sentMessages != undefined) {
            console.log("msg1", res.data.sentMessages.length);
            setMessages(res.data.sentMessages);
          }
          setShowLoadingSpinner(false);
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  React.useEffect(() => {
    const unsubscribe = props.navigation.addListener("focus", () => {
      const accs = store.accs_array;
      setAccList(accs);
      setSelectedAccount(accs[0]?.acc_id);
    });

    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe;
  }, [props.navigation]);

  React.useEffect(() => {
    // const unsubscribe = props.navigation.addListener("focus", () => {

    // });

    // // Return the function to unsubscribe from the event so it gets removed on unmount
    // return unsubscribe;
    console.log("hit!");
    setMessages([]);
    getAllMessages(selectedAccount)
      .then(() => {
        console.log("msgs", messages);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [selectedAccount]);
  return (
    <Observer>
      {() => (
        <>
          <DashboardLayout
            title=""
            displayMenuButton={false}
            displayScreenTitle={false}
            displayAlternateMobileHeader={false}
            nav={props.navigation}
          >
            <ScrollView>
              <Text
                fontSize={"xl"}
                fontWeight={"semibold"}
                // paddingLeft={"4"}
                mt={8}
                px={{ base: 4, md: 6 }}
              >
                Recent Account Messages
              </Text>
              <VStack>
                <VStack
                  px={{ base: 4, md: 6 }}
                  pt={{ base: 4, md: 6 }}
                  pb={{ base: 4, md: 6 }}
                  mt={"2"}
                  minHeight={"100"}
                >
                  {accList.length > 1 && (
                    <Select
                      selectedValue={selectedAccount}
                      minWidth="200"
                      accessibilityLabel="Select Account"
                      placeholder="Select Account"
                      _selectedItem={{
                        bg: "primary.600",
                      }}
                      mt={1}
                      onValueChange={(itemValue) => {
                        console.log("changing", itemValue);
                        setSelectedAccount(itemValue);
                      }}
                    >
                      {accList?.length > 0 &&
                        accList.map(({ acc_id, acc_name }) => {
                          return (
                            <Select.Item
                              key={acc_id}
                              label={acc_name}
                              value={acc_id}
                            />
                          );
                        })}
                    </Select>
                  )}
                </VStack>
                {showLoadingSpinner && (
                  <HStack space={2} my={4} justifyContent="center">
                    <Spinner />
                    <Heading color="primary.500" fontSize="md">
                      Loading Messages
                    </Heading>
                  </HStack>
                )}
                <VStack px={{ base: 4, md: 6 }}>
                  {messages.map(
                    ({
                      title,
                      time_created,
                      timezone,
                      sent_message_id,
                      qdoc_response_id,
                      acc_id,
                    }) => {
                      return (
                        <MessageCard
                          title={title}
                          time_created={time_created}
                          timezone={timezone}
                          sent_message_id={sent_message_id}
                          qdoc_response_id={qdoc_response_id}
                          acc_id={acc_id}
                          navigation={props.navigation}
                        ></MessageCard>
                      );
                    }
                  )}
                </VStack>
              </VStack>
            </ScrollView>
          </DashboardLayout>
          <MobileFooter nav={props.navigation} store={store} />
        </>
      )}
    </Observer>
  );
}
