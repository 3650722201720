import React, { useState } from "react";
import {
  Button,
  HStack,
  VStack,
  Text,
  Link,
  Checkbox,
  useToast,
  Divider,
  Image,
  useColorModeValue,
  IconButton,
  Icon,
  Pressable,
  Center,
  Hidden,
  StatusBar,
  Stack,
  Box,
} from "native-base";
import { AntDesign, Entypo } from "@expo/vector-icons";
import FloatingLabelInput from "./components/FloatingLabelInput";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { finishSetup, reportLoginFlowDebug } from "../../utilities/network";
import { StoreContext } from "../../utilities/store";
import { Observer } from "mobx-react";

export function FinishSignUpForm({ props }) {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [showPass, setShowPass] = React.useState(false);

  const toast = useToast();

  const store = React.useContext(StoreContext);

  const handleClickSetup = () => {
    if (pass == confirmPass) {
      finishSetup(store.setupCode, email.toLowerCase(), pass, store.setupOrgId)
        .then(() => {
          props.navigation.navigate("Splash");
          reportLoginFlowDebug(
            false,
            "setup-code-success",
            email.toLowerCase()
          );
        })
        .catch((err) => {
          if (err.statusCode == "SP507") {
            toast.show({
              description: "Email already in use (507)",
              placement: "top",
            });
          } else if (err.statusCode == "SP105") {
            toast.show({
              description: "Email already in use (105)",
              placement: "top",
            });
          } else {
            console.log("err", err);
          }
          reportLoginFlowDebug(true, "finish-setup-code-1", {
            e: err,
            setupCode: store.setupCode,
          });
          //fail toast
        });
    } else {
      toast.show({
        description: "Passwords do not match",
        placement: "top",
      });
    }
  };

  return (
    <Observer>
      {() => (
        <KeyboardAwareScrollView
          contentContainerStyle={{
            flexGrow: 1,
          }}
          style={{
            flex: 1,
          }}
        >
          <VStack
            flex="1"
            px="6"
            py="9"
            _light={{
              bg: "white",
            }}
            _dark={{
              bg: "coolGray.800",
            }}
            space="3"
            justifyContent="space-between"
            borderTopRightRadius={{
              base: "2xl",
              md: "xl",
            }}
            borderBottomRightRadius={{
              base: "0",
              md: "xl",
            }}
            borderTopLeftRadius={{
              base: "2xl",
              md: "0",
            }}
          >
            <VStack space="7">
              <Hidden till="md">
                <Text fontSize="xl" fontWeight="normal">
                  Finish Account Setup
                </Text>
                <Text mt="2">
                  Hi There, {store.userFirstName} {store.userLastName}!
                </Text>
                <Text>
                  Let's finish setting up your account for {store.setupOrgName}.
                </Text>
                <Text mt="8">
                  Please provide your email and a password to sign in with.
                </Text>
              </Hidden>
              <VStack>
                <VStack space="3">
                  <VStack
                    space={{
                      base: "7",
                      md: "4",
                    }}
                  >
                    <FloatingLabelInput
                      isRequired
                      label="Email"
                      labelColor="#9ca3af"
                      labelBGColor={useColorModeValue("#fff", "#1f2937")}
                      borderRadius="4"
                      defaultValue={email}
                      onChangeText={(txt) => setEmail(txt)}
                      _text={{
                        fontSize: "sm",
                        fontWeight: "medium",
                      }}
                      _dark={{
                        borderColor: "coolGray.700",
                      }}
                      _light={{
                        borderColor: "coolGray.300",
                      }}
                    />
                    <FloatingLabelInput
                      isRequired
                      type={showPass ? "" : "password"}
                      label="Password"
                      borderRadius="4"
                      labelColor="#9ca3af"
                      labelBGColor={useColorModeValue("#fff", "#1f2937")}
                      defaultValue={pass}
                      onChangeText={(txt) => setPass(txt)}
                      InputRightElement={
                        <IconButton
                          variant="unstyled"
                          icon={
                            <Icon
                              size="4"
                              color="coolGray.400"
                              as={Entypo}
                              name={showPass ? "eye-with-line" : "eye"}
                            />
                          }
                          onPress={() => {
                            setShowPass(!showPass);
                          }}
                        />
                      }
                      _text={{
                        fontSize: "sm",
                        fontWeight: "medium",
                      }}
                      _dark={{
                        borderColor: "coolGray.700",
                      }}
                      _light={{
                        borderColor: "coolGray.300",
                      }}
                    />
                    <FloatingLabelInput
                      isRequired
                      type={showPass ? "" : "password"}
                      label="Confirm Password"
                      borderRadius="4"
                      labelColor="#9ca3af"
                      labelBGColor={useColorModeValue("#fff", "#1f2937")}
                      defaultValue={confirmPass}
                      onChangeText={(txt) => setConfirmPass(txt)}
                      InputRightElement={
                        <IconButton
                          variant="unstyled"
                          icon={
                            <Icon
                              size="4"
                              color="coolGray.400"
                              as={Entypo}
                              name={showPass ? "eye-with-line" : "eye"}
                            />
                          }
                          onPress={() => {
                            setShowPass(!showPass);
                          }}
                        />
                      }
                      _text={{
                        fontSize: "sm",
                        fontWeight: "medium",
                      }}
                      _dark={{
                        borderColor: "coolGray.700",
                      }}
                      _light={{
                        borderColor: "coolGray.300",
                      }}
                    />
                  </VStack>
                  <Link
                    ml="auto"
                    _text={{
                      fontSize: "xs",
                      fontWeight: "bold",
                      textDecoration: "none",
                    }}
                    _light={{
                      _text: {
                        color: "primary.900",
                      },
                    }}
                    _dark={{
                      _text: {
                        color: "primary.500",
                      },
                    }}
                  >
                    Forgot password?
                  </Link>
                  {/* <Checkbox
                alignItems="flex-start"
                mt="5"
                isChecked
                value="demo"
                colorScheme="primary"
                accessibilityLabel="Remember me"
              >
                <Text
                  pl="3"
                  fontWeight="normal"
                  _light={{
                    color: "coolGray.800",
                  }}
                  _dark={{
                    color: "coolGray.400",
                  }}
                >
                  Remember me and keep me logged in
                </Text>
              </Checkbox> */}
                  <Button
                    mt="5"
                    size="md"
                    borderRadius="4"
                    _text={{
                      fontWeight: "medium",
                    }}
                    _light={{
                      bg: "primary.900",
                    }}
                    _dark={{
                      bg: "primary.700",
                    }}
                    onPress={() => {
                      handleClickSetup();
                    }}
                  >
                    Finish Setup
                  </Button>
                  <HStack
                    mt="5"
                    space="2"
                    mb={{
                      base: 6,
                      md: 7,
                    }}
                    alignItems="center"
                    justifyContent="center"
                  ></HStack>
                </VStack>
              </VStack>
            </VStack>
          </VStack>
        </KeyboardAwareScrollView>
      )}
    </Observer>
  );
}
export default function FinishSignUp(props) {
  const store = React.useContext(StoreContext);
  return (
    <Observer>
      {() => (
        <>
          <StatusBar
            translucent
            backgroundColor="transparent"
            barStyle="light-content"
          />
          <Box
            safeAreaTop
            _light={{
              bg: "primary.900",
            }}
            _dark={{
              bg: "coolGray.900",
            }}
          />
          <Center
            my="auto"
            _dark={{
              bg: "coolGray.900",
            }}
            _light={{
              bg: "primary.900",
            }}
            flex="1"
            p={{ md: 8 }}
          >
            <Stack
              flexDirection={{
                base: "column",
                md: "row",
              }}
              w="100%"
              maxW={{
                md: "1016px",
              }}
              flex={{
                base: "1",
                md: "none",
              }}
            >
              <Hidden from="md">
                <VStack px="4" mt="4" mb="5" space="9">
                  <HStack space="2" alignItems="center">
                    <IconButton
                      variant="unstyled"
                      pl="0"
                      onPress={() => {
                        props.navigation.navigate("Splash");
                      }}
                      icon={
                        <Icon
                          size="6"
                          as={AntDesign}
                          name="arrowleft"
                          color="coolGray.50"
                        />
                      }
                    />
                    <Text color="coolGray.50" fontSize="lg">
                      Finish Setup
                    </Text>
                  </HStack>
                  <VStack space="2">
                    <Text fontSize="3xl" fontWeight="bold" color="coolGray.50">
                      Hi There, {store.userFirstName} {store.userLastName}!
                    </Text>
                    <Text
                      fontSize="md"
                      fontWeight="normal"
                      _dark={{
                        color: "coolGray.400",
                      }}
                      _light={{
                        color: "primary.300",
                      }}
                    >
                      Let's finish setting up your account for{" "}
                      {store.setupOrgName}
                    </Text>
                    <Text
                      fontSize="md"
                      fontWeight="normal"
                      _dark={{
                        color: "coolGray.400",
                      }}
                      _light={{
                        color: "primary.300",
                      }}
                    >
                      Please provide your email and a password to sign in with.
                    </Text>
                  </VStack>
                </VStack>
              </Hidden>
              <Hidden till="md">
                <Center
                  flex="1"
                  bg="primary.700"
                  borderTopLeftRadius={{
                    base: "0",
                    md: "xl",
                  }}
                  borderBottomLeftRadius={{
                    base: "0",
                    md: "xl",
                  }}
                >
                  <Image
                    h="24"
                    size="80"
                    alt="Blaze Relay Logo"
                    resizeMode={"contain"}
                    source={require("../../assets/BlazeRelayLogo.png")}
                  />
                </Center>
              </Hidden>
              <FinishSignUpForm props={props} />
            </Stack>
          </Center>
        </>
      )}
    </Observer>
  );
}
