import React, { useState, useEffect } from "react";
import { Observer, useObserver } from "mobx-react";
import { MaterialIcons } from "@expo/vector-icons";
import { Audio } from "expo-av";

import "intl";
import "intl/locale-data/jsonp/en";

import {
  Box,
  HStack,
  Icon,
  Text,
  VStack,
  Button,
  Avatar,
  Image,
  ScrollView,
  Pressable,
  Hidden,
  Select,
  Center,
  IconButton,
  ArrowBackIcon,
  FormControl,
  Modal,
  Input,
  Checkbox,
  useToast,
  Spinner,
  Heading,
  CheckIcon,
} from "native-base";

import { StoreContext } from "../../utilities/store";
import DashboardLayout from "../../layouts/DashboardLayout";
import { fetchAPIJSON } from "../../utilities/network";
// import Sound from "react-native-sound";
// import DatePicker from "react-native-date-picker";

function MobileFooter(props) {
  const store = React.useContext(StoreContext);
  return (
    <Hidden from="md">
      <HStack
        justifyContent="space-between"
        safeAreaBottom
        h="20"
        width="100%"
        position="absolute"
        left="0"
        right="0"
        bottom="0"
        overflow="hidden"
        alignSelf="center"
        borderTopLeftRadius="24"
        borderTopRightRadius="24"
        _light={{ backgroundColor: "white" }}
        _dark={{ backgroundColor: "coolGray.800" }}
      >
        <Button
          variant="ghost"
          flex={1}
          colorScheme="coolGray"
          _stack={{
            flexDirection: "column",
          }}
          startIcon={<Icon as={MaterialIcons} name="message" size="5" />}
          onPress={() => {
            props.nav.navigate("HomeScreen");
          }}
        ></Button>
        {store.accessOnCall && (
          <Button
            variant="ghost"
            flex={1}
            colorScheme="coolGray"
            _stack={{
              flexDirection: "column",
            }}
            startIcon={
              <Icon as={MaterialIcons} name="perm-contact-calendar" size="5" />
            }
            onPress={() => {
              props.nav.navigate("OnCallScreen");
            }}
          ></Button>
        )}
        {store.accessGhostCall && (
          <Button
            variant="ghost"
            flex={1}
            colorScheme="coolGray"
            _stack={{
              flexDirection: "column",
            }}
            startIcon={
              <Icon as={MaterialIcons} name="phone-in-talk" size="5" />
            }
            onPress={() => {
              props.nav.navigate("GhostCallScreen");
            }}
          ></Button>
        )}
        {store.accessCallRecordings && (
          <Button
            variant="ghost"
            flex={1}
            colorScheme="coolGray"
            _stack={{
              flexDirection: "column",
            }}
            startIcon={
              <Icon
                as={MaterialIcons}
                name="multitrack-audio"
                size="5"
                color="primary.500"
              />
            }
            onPress={() => {}}
          ></Button>
        )}
        {store.accessDatatables && (
          <Button
            variant="ghost"
            flex={1}
            colorScheme="coolGray"
            _stack={{
              flexDirection: "column",
            }}
            startIcon={<Icon as={MaterialIcons} name="view-list" size="5" />}
            onPress={() => {
              props.nav.navigate("DataTablesScreen");
            }}
          ></Button>
        )}

        <Button
          variant="ghost"
          flex={1}
          colorScheme="coolGray"
          _stack={{
            flexDirection: "column",
          }}
          startIcon={<Icon as={MaterialIcons} name="exit-to-app" size="5" />}
          onPress={() => {
            store.signOut();
            props.nav.navigate("Splash");
          }}
        ></Button>
      </HStack>
    </Hidden>
  );
}

const CallRecordingCard = ({
  call_id,
  call_session_id,
  acc_name,
  acc_id,
  direction,
  start_time,
  end_time,
  hangup_cause,
  duration,
  number_to,
  number_from,
  navigation,
}) => {
  const store = React.useContext(StoreContext);
  const [cardOpen, setCardOpen] = useState(false);
  const [recordingURL, setRecordingURL] = useState(null);
  const [hasRecording, setHasRecording] = useState(null);
  const [recordingSound, setRecordingSound] = useState(undefined);
  const [callDuration, setCallDuration] = useState(duration / 1000);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currTime, setCurrTime] = useState(0);

  return (
    <VStack
      mt={1}
      mb={1}
      width={"100%"}
      borderRadius="lg"
      borderColor={"warmGray.100"}
      borderWidth={"1"}
      bg={"white"}
      shadow={"2"}
      p={{ base: 4, md: 6 }}
    >
      <Pressable
        onPress={() => {
          if (!recordingURL) {
            fetchAPIJSON("api/v1/retCallRecording", store, navigation, {
              call_session_id: call_session_id,
            }).then(async (res) => {
              const callRecordingUrl = res?.data?.callRecordingUrl;

              const _onPlaybackStatusUpdate = (playbackStatus) => {
                if (!playbackStatus.isLoaded) {
                  // Update your UI for the unloaded state
                  if (playbackStatus.error) {
                    console.log(
                      `Encountered a fatal error during playback: ${playbackStatus.error}`
                    );
                    // Send Expo team the error on Slack or the forums so we can help you debug!
                  }
                } else {
                  // setShowLoadingSpinner(false);
                  // console.log(playbackStatus);
                  setCurrTime(playbackStatus.positionMillis / 1000);

                  if (playbackStatus.isPlaying) {
                    setIsPlaying(true);
                    setCallDuration(playbackStatus.durationMillis / 1000);
                  } else {
                    setIsPlaying(false);
                  }

                  if (playbackStatus.isBuffering) {
                    // Update your UI for the buffering state
                  }

                  if (
                    playbackStatus.didJustFinish &&
                    !playbackStatus.isLooping
                  ) {
                    setIsPlaying(false);
                  }
                }
              };

              if (callRecordingUrl != null) {
                const { sound } = await Audio.Sound.createAsync(
                  callRecordingUrl
                );
                sound.setOnPlaybackStatusUpdate(_onPlaybackStatusUpdate);
                setRecordingSound(sound);
                setRecordingURL(callRecordingUrl);
                setCurrTime(0);
              } else {
                setHasRecording(false);
                setRecordingURL("none");
              }
            });
          }
          setCardOpen(!cardOpen);
        }}
      >
        <Text fontSize="md" fontWeight={"semibold"} color={"primary.700"}>
          {new Date(start_time).toLocaleString(navigator.language, {
            hour: "2-digit",
            minute: "2-digit",
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            timeZoneName: "short",
          })}{" "}
        </Text>
        <HStack>
          <Text>
            {number_from} - {Math.round(callDuration)}
            {" Seconds"}
          </Text>
        </HStack>
      </Pressable>
      {cardOpen && recordingURL == null && (
        <HStack space={2} my={4} justifyContent="center">
          <Spinner />
          <Heading color="primary.500" fontSize="md">
            Loading Call Recording
          </Heading>
        </HStack>
      )}
      {cardOpen && hasRecording == false && <Text>No Recording</Text>}
      {cardOpen && recordingSound != null && (
        <VStack mt={4} alignItems={"center"}>
          {recordingSound != null && (
            <Text>
              {Math.floor((currTime % (1000 * 60)) / 60)}:
              {Math.floor(currTime % 60)
                .toString()
                .padStart(2, "0")}{" "}
              - {Math.floor((callDuration % (1000 * 60)) / 60)}:
              {Math.floor(callDuration % 60)
                .toString()
                .padStart(2, "0")}
            </Text>
          )}

          <HStack alignItems={"center"} mt={6}>
            <Box mx={4}>
              <MaterialIcons
                name="replay-10"
                size={36}
                color="black"
                onPress={() => {
                  recordingSound.setPositionAsync(currTime - 10);
                }}
              />
            </Box>
            {!isPlaying && (
              <Box mx={4}>
                <MaterialIcons
                  name="play-circle-outline"
                  size={36}
                  color="black"
                  onPress={async () => {
                    setIsPlaying(true);
                    recordingSound.playAsync().then(() => {
                      // setIsPlaying
                    });
                    // recordingSound.play((success) => {
                    //   if (success) {
                    //     console.log("successfully finished playing");
                    //     setIsPlaying(false);
                    //   } else {
                    //     console.log(
                    //       "playback failed due to audio decoding errors"
                    //     );
                    //   }
                    // });
                  }}
                />
              </Box>
            )}
            {isPlaying && (
              <Box mx={4}>
                <MaterialIcons
                  name="pause-circle-outline"
                  size={36}
                  color="black"
                  onPress={() => {
                    recordingSound.pauseAsync(() => {
                      setIsPlaying(false);
                    });
                  }}
                />
              </Box>
            )}

            <Box mx={4}>
              <MaterialIcons
                name="forward-10"
                size={36}
                color="black"
                onPress={() => {
                  recordingSound.setPositionAsync(currTime * 1000 + 10000); //convert to ms then add 10 seconds
                }}
              />
            </Box>
          </HStack>
        </VStack>
      )}
    </VStack>
  );
};

export default function CallRecordingsScreen(props) {
  const store = React.useContext(StoreContext);
  const toast = useToast();

  const getCallLogs = () => {
    setShowLoadingSpinner(true);
    let __time_start = null;
    let __time_end = null;
    if (checkBoxValues[0] == "true") {
      __time_start = startDate;
      __time_end = endDate;
    }
    fetchAPIJSON("api/v1/blaze/retAccCallLogs", store, props.navigation, {
      acc_id: selectedAcc,
      time_start: __time_start,
      time_end: __time_end,
    }).then((res) => {
      // console.log(res);
      setCallLogs(res.data.callLogs);
      setShowLoadingSpinner(false);
    });
  };

  const [accList, setAccList] = useState(["Loading Accounts"]);
  const [selectedAcc, setSelectedAcc] = React.useState("");
  const [callLogs, setCallLogs] = React.useState([]);
  const [checkBoxValues, setCheckBoxValues] = React.useState([]);
  const [showLoadingSpinner, setShowLoadingSpinner] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [Eopen, setEOpen] = useState(false);
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());

  React.useEffect(() => {
    const unsubscribe = props.navigation.addListener("focus", () => {
      const accs = store.accs_array;
      setAccList(accs);
      setSelectedAcc(accs[0]?.acc_id);
    });

    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe;
  }, [props.navigation]);

  React.useEffect(() => {
    setCallLogs([]);
    getCallLogs();
  }, [selectedAcc]);

  return (
    <Observer>
      {() => (
        <>
          <DashboardLayout
            title=""
            displayMenuButton={false}
            displayScreenTitle={false}
            displayAlternateMobileHeader={false}
            nav={props.navigation}
          >
            <ScrollView>
              <Text
                fontSize={"xl"}
                fontWeight={"semibold"}
                mt={8}
                px={{ base: 4, md: 6 }}
              >
                Call Recordings
              </Text>
              <VStack
                px={{ base: 4, md: 6 }}
                pt={{ base: 4, md: 6 }}
                pb={{ base: 4, md: 6 }}
                borderRadius={{ md: "8" }}
                width={{ base: "100%", md: "100%" }}
              >
                {accList.length > 1 && (
                  <Select
                    selectedValue={selectedAcc}
                    minWidth="200"
                    accessibilityLabel="Select Account"
                    placeholder="Select Account"
                    _selectedItem={{
                      bg: "primary.600",
                    }}
                    mt={1}
                    onValueChange={(itemValue) => setSelectedAcc(itemValue)}
                  >
                    {accList?.length > 0 &&
                      accList.map(({ acc_id, acc_name }) => {
                        return (
                          <Select.Item
                            key={acc_id}
                            label={acc_name}
                            value={acc_id}
                          />
                        );
                      })}
                  </Select>
                )}
              </VStack>
              {showLoadingSpinner && (
                <HStack space={2} my={4} justifyContent="center">
                  <Spinner />
                  <Heading color="primary.500" fontSize="md">
                    Searching Calls
                  </Heading>
                </HStack>
              )}
              {callLogs?.length > 0 && (
                <VStack
                  px={{ base: 4, md: 6 }}
                  pt={{ base: 4, md: 6 }}
                  pb={{ base: 24, md: 6 }}
                  borderRadius={{ md: "8" }}
                  alignItems="center"
                  width={{ base: "100%", md: "100%" }}
                  minHeight={{ base: "200", md: "400" }}
                >
                  {callLogs.map(
                    ({
                      call_id,
                      call_session_id,
                      acc_name,
                      acc_id,
                      direction,
                      start_time,
                      end_time,
                      hangup_cause,
                      duration,
                      number_to,
                      number_from,
                    }) => {
                      return (
                        <CallRecordingCard
                          start_time={start_time}
                          end_time={end_time}
                          duration={duration}
                          call_session_id={call_session_id}
                          number_from={number_from}
                          navigation={props.navigation}
                          call_id={call_id}
                          key={call_id}
                        />
                      );
                    }
                  )}
                </VStack>
              )}
            </ScrollView>
          </DashboardLayout>
          <MobileFooter nav={props.navigation} />
        </>
      )}
    </Observer>
  );
}
