import React, { useState, useEffect, useMemo } from "react";
import { Observer, useObserver } from "mobx-react";
import { MaterialIcons } from "@expo/vector-icons";

import "intl";
import "intl/locale-data/jsonp/en";

import {
  Box,
  HStack,
  Icon,
  Text,
  VStack,
  Button,
  Avatar,
  Image,
  ScrollView,
  Pressable,
  Hidden,
  Select,
  Center,
  IconButton,
  ArrowBackIcon,
  FormControl,
  Modal,
  Input,
  Checkbox,
  useToast,
  Spinner,
  Heading,
  CheckIcon,
} from "native-base";

import { StoreContext } from "../../utilities/store";
import DashboardLayout from "../../layouts/DashboardLayout";
import { fetchAPIJSON } from "../../utilities/network";

function MobileFooter(props) {
  const store = React.useContext(StoreContext);

  const [logoutOpen, setLogoutOpen] = React.useState(false);
  return (
    <Hidden from="md">
      <HStack
        justifyContent="space-between"
        safeAreaBottom
        h="20"
        width="100%"
        overflow="hidden"
        // alignSelf="center"
        borderTopLeftRadius={"xl"}
        borderTopRightRadius={"xl"}
        _light={{ backgroundColor: "primary.600" }}
        _dark={{ backgroundColor: "coolGray.800" }}
      >
        <Button
          variant="ghost"
          flex={1}
          colorScheme="coolGray"
          _stack={{
            flexDirection: "column",
          }}
          startIcon={<Icon as={MaterialIcons} name="message" size="5" />}
          onPress={() => {
            props.nav.navigate("HomeScreen");
          }}
        ></Button>
        {store.accessOnCall && (
          <Button
            variant="ghost"
            flex={1}
            _stack={{
              flexDirection: "column",
            }}
            colorScheme="coolGray"
            startIcon={
              <Icon as={MaterialIcons} name="perm-contact-calendar" size="5" />
            }
            onPress={() => {
              props.nav.navigate("OnCallScreen");
            }}
          ></Button>
        )}
        {store.accessGhostCall && (
          <Button
            variant="ghost"
            flex={1}
            colorScheme="coolGray"
            _stack={{
              flexDirection: "column",
            }}
            startIcon={
              <Icon as={MaterialIcons} name="phone-in-talk" size="5" />
            }
            onPress={() => {
              props.nav.navigate("GhostCallScreen");
            }}
          ></Button>
        )}
        {store.accessCallRecordings && (
          <Button
            variant="ghost"
            flex={1}
            colorScheme="coolGray"
            _stack={{
              flexDirection: "column",
            }}
            startIcon={
              <Icon as={MaterialIcons} name="multitrack-audio" size="5" />
            }
            onPress={() => {
              props.nav.navigate("CallRecordingsScreen");
            }}
          ></Button>
        )}
        {store.accessDatatables && (
          <Button
            variant="ghost"
            flex={1}
            colorScheme="coolGray"
            _stack={{
              flexDirection: "column",
            }}
            startIcon={
              <Icon
                as={MaterialIcons}
                name="view-list"
                size="5"
                color="white"
              />
            }
            onPress={() => {}}
          ></Button>
        )}
        <Button
          variant="ghost"
          flex={1}
          colorScheme="coolGray"
          _stack={{
            flexDirection: "column",
          }}
          startIcon={<Icon as={MaterialIcons} name="settings" size="5" />}
          onPress={() => {
            props.nav.navigate("SettingsScreen");
          }}
        ></Button>
      </HStack>
    </Hidden>
  );
}

const TableRowCard = ({ row, table_name, hash, acc_id, getTableData, nav }) => {
  const [editMode, setEditMode] = useState(false);
  const [rowValues, setRowValues] = React.useState({});
  const store = React.useContext(StoreContext);
  const handleRowValueChange = React.useCallback((newText, colTitle) => {
    setRowValues((rowValues) => {
      let newRowValues = rowValues;
      newRowValues[colTitle] = newText;
      return newRowValues;
    });
  }, []);

  const saveTableChanges = () => {
    let finalRowValues = rowValues;
    Object.entries(row).map((rowData) => {
      const columnTitle = rowData[0];
      const columnValue = rowData[1];

      if (!finalRowValues.hasOwnProperty(columnTitle)) {
        finalRowValues[columnTitle] = columnValue;
      }
    });

    let valueArrays = Object.entries(finalRowValues);
    let newTableColumns = [];
    let table_pk = null;
    valueArrays.forEach((colVal) => {
      const column_name = colVal[0];
      const data = colVal[1];
      if (column_name == "table_pk") {
        table_pk = data;
      }
      newTableColumns.push({ column_name, data });
    });

    fetchAPIJSON("api/v1/blaze/updateTableData", store, nav, {
      acc_id: acc_id,
      table_name: table_name,
      hash: hash,
      table_pk: table_pk,
      newTableColumns: newTableColumns,
    })
      .then((res) => {
        getTableData();
      })
      .catch((err) => {
        console.log(err);
      });
    setRowValues({});
  };
  return (
    <VStack
      minWidth={"100%"}
      borderRadius="lg"
      borderColor={"warmGray.100"}
      borderWidth={"1"}
      bg={"white"}
      shadow={"2"}
      mt={4}
      py={2}
    >
      {Object.entries(row).map((rowData) => {
        const columnTitle = rowData[0];
        const columnValue = rowData[1];

        if (columnTitle != "table_pk") {
          return (
            <HStack py={1} key={columnTitle}>
              <Text mx={2} width={"35%"}>
                {columnTitle}:
              </Text>
              <Box width={"55%"}>
                {!editMode && <Text>{columnValue}</Text>}
                {editMode && (
                  <Input
                    defaultValue={columnValue}
                    value={rowValues[columnTitle]}
                    onChangeText={(text) => {
                      handleRowValueChange(text, columnTitle);
                    }}
                    variant={"filled"}
                  ></Input>
                )}
              </Box>
            </HStack>
          );
        }
      })}
      <HStack mt={4} flexDirection={"row-reverse"}>
        <Pressable
          mr={6}
          onPress={() => {
            if (editMode) {
              saveTableChanges();
            }

            setEditMode(!editMode);
          }}
        >
          {!editMode && (
            <Icon as={MaterialIcons} name="edit" size="7" color={"green.600"} />
          )}
          {editMode && (
            <Icon as={MaterialIcons} name="save" size="7" color={"blue.600"} />
          )}
        </Pressable>
      </HStack>
    </VStack>
  );
};

const TableCard = ({
  table_name,
  hash,
  columns,
  acc_id,
  setModalData,
  refreshTrigger,
  openModal,
  nav,
}) => {
  const store = React.useContext(StoreContext);
  const [cardOpen, setCardOpen] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  let columnsByName = {};
  const [showTableSpinner, setShowTableSpinner] = useState(true);

  useEffect(() => TriggeredFunc(), [refreshTrigger]);

  useEffect(() => {
    if (cardOpen == true) {
      getTableData();
    }
  }, [cardOpen]);

  const TriggeredFunc = () => {
    getTableData();
  };

  const getTableData = () => {
    setShowTableSpinner(true);
    fetchAPIJSON("api/v1/blaze/retTableData", store, nav, {
      acc_id: acc_id,
      table_name: table_name,
      hash: hash,
    })
      .then((res) => {
        const rows = res.data?.column_data;
        setTableRows(rows);

        let tempColumnDict = {};
        columns.forEach((column) => {
          tempColumnDict[column["column_name"]] = column;
        });

        columnsByName = tempColumnDict;

        setShowTableSpinner(false);
      })
      .catch(() => {});
  };

  const handleCreateNewEntry = () => {
    setModalData({
      table_name: table_name,
      hash: hash,
      columns: columns,
      acc_id: acc_id,
    });

    openModal();
  };

  return (
    <Pressable
      // backgroundColor={"pink.500"}
      onPress={() => {
        setCardOpen(!cardOpen);
      }}
      width={"100%"}
    >
      <VStack
        p={{ base: 4, md: 6 }}
        minHeight={"24"}
        borderColor={"warmGray.300"}
        // borderTopWidth={"2"}
        borderBottomWidth={"2"}
      >
        <HStack>
          <Text
            ml={"2"}
            fontSize="xl"
            fontWeight={"semibold"}
            color={"primary.600"}
          >
            {table_name}
          </Text>
          {cardOpen && !showTableSpinner && (
            <Pressable
              marginLeft={"auto"}
              mr={"2"}
              onPress={() => {
                handleCreateNewEntry();
              }}
            >
              <Icon
                as={MaterialIcons}
                name="add"
                size="7"
                color={"green.600"}
              />
            </Pressable>
          )}
        </HStack>

        {cardOpen && showTableSpinner && (
          <HStack space={2} my={4} justifyContent="center">
            <Spinner />
            <Heading color="primary.500" fontSize="md">
              Loading {table_name}
            </Heading>
          </HStack>
        )}
        {cardOpen && (
          <Pressable onPress={() => {}}>
            <VStack mt={4} alignItems={"center"}>
              {tableRows?.length > 0 &&
                tableRows.map((row) => {
                  // create one card for each row
                  return (
                    <TableRowCard
                      key={row.table_pk}
                      row={row}
                      table_name={table_name}
                      hash={hash}
                      acc_id={acc_id}
                      getTableData={getTableData}
                      nav={nav}
                    ></TableRowCard>
                  );
                })}
            </VStack>
          </Pressable>
        )}
      </VStack>
    </Pressable>
  );
};

export default function DataTablesScreen(props) {
  const store = React.useContext(StoreContext);
  const toast = useToast();

  const [accList, setAccList] = useState(["Loading Accounts"]);
  const [selectedAcc, setSelectedAcc] = React.useState("");
  const [showLoadingSpinner, setShowLoadingSpinner] = React.useState(false);

  const [dataTables, setDataTables] = React.useState([]);

  const [modalVisible, setModalVisible] = React.useState(false);
  const [modalData, setModalData] = React.useState({});
  const [refreshTrigger, setRefreshTrigger] = React.useState(false);
  const [rowValues, setRowValues] = React.useState({});
  const handleRowValueChange = React.useCallback((newText, colTitle) => {
    setRowValues((rowValues) => {
      let newRowValues = rowValues;
      newRowValues[colTitle] = newText;
      return newRowValues;
    });
  }, []);

  const saveNewTableEntry = () => {
    let finalRowValues = rowValues;

    let valueArrays = Object.entries(finalRowValues);
    let newTableColumns = [];

    valueArrays.forEach((colVal) => {
      const column_name = colVal[0];
      const data = colVal[1];

      newTableColumns.push({ column_name, data });
    });

    fetchAPIJSON("api/v1/blaze/createTableEntry", store, props.navigation, {
      acc_id: modalData?.acc_id,
      table_name: modalData?.table_name,
      hash: modalData?.hash,
      newTableColumns: newTableColumns,
    })
      .then((res) => {
        setRefreshTrigger(!refreshTrigger);
        setRowValues({});
        setModalVisible(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const openModal = () => {
    setModalVisible(true);
    setRowValues({});
  };

  const getAccDataTables = () => {
    setShowLoadingSpinner(true);

    fetchAPIJSON("api/v1/blaze/retAccDataTables", store, props.navigation, {
      acc_id: selectedAcc,
    }).then((res) => {
      setDataTables(res.data?.tables);

      setShowLoadingSpinner(false);
    });
  };

  React.useEffect(() => {
    const unsubscribe = props.navigation.addListener("focus", () => {
      const accs = store.accs_array;
      setAccList(accs);
      setSelectedAcc(accs[0]?.acc_id);
    });

    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe;
  }, [props.navigation]);

  React.useEffect(() => {
    setDataTables([]);
    getAccDataTables();
  }, [selectedAcc]);
  return (
    <Observer>
      {() => (
        <>
          <DashboardLayout
            title=""
            displayMenuButton={false}
            displayScreenTitle={false}
            displayAlternateMobileHeader={false}
            nav={props.navigation}
          >
            <ScrollView>
              <Text
                fontSize={"xl"}
                fontWeight={"semibold"}
                // paddingLeft={"4"}
                mt={8}
                px={{ base: 4, md: 6 }}
              >
                Data Tables
              </Text>
              <VStack
                px={{ base: 4, md: 6 }}
                pt={{ base: 4, md: 6 }}
                pb={{ base: 4, md: 6 }}
                mb={"4"}
                minHeight={"100"}
              >
                {accList.length > 1 && (
                  <Select
                    selectedValue={selectedAcc}
                    minWidth="200"
                    accessibilityLabel="Select Account"
                    placeholder="Select Account"
                    _selectedItem={{
                      bg: "primary.600",
                    }}
                    mt={1}
                    onValueChange={(itemValue) => setSelectedAcc(itemValue)}
                  >
                    {accList?.length > 0 &&
                      accList.map(({ acc_id, acc_name }) => {
                        return (
                          <Select.Item
                            key={acc_id}
                            label={acc_name}
                            value={acc_id}
                          />
                        );
                      })}
                  </Select>
                )}
              </VStack>
              {showLoadingSpinner && (
                <HStack space={2} my={4} justifyContent="center">
                  <Spinner />
                  <Heading color="primary.500" fontSize="md">
                    Searching Data Tables
                  </Heading>
                </HStack>
              )}
              {dataTables?.length > 0 && (
                <VStack
                  pt={{ base: 4, md: 6 }}
                  pb={{ base: 24, md: 6 }}
                  alignItems="center"
                  borderColor={"warmGray.300"}
                  borderTopWidth={"2"}
                >
                  {dataTables.map(({ table_name, hash, columns }) => {
                    return (
                      <TableCard
                        key={table_name + hash}
                        table_name={table_name}
                        hash={hash}
                        columns={columns}
                        acc_id={selectedAcc}
                        setModalData={setModalData}
                        openModal={openModal}
                        refreshTrigger={refreshTrigger}
                        nav={props.navigation}
                      />
                    );
                  })}
                </VStack>
              )}
              <Modal
                isOpen={modalVisible}
                onClose={() => setModalVisible(false)}
                avoidKeyboard
                size="full"
                w={{ md: "40%" }}
                alignSelf={"center"}
              >
                {/* tableRows: tableRows, table_name: table_name, hash: hash,
                columns: columns, acc_id: acc_id, */}
                <Modal.Content>
                  <Modal.CloseButton />
                  <Modal.Header>Create New Entry</Modal.Header>
                  <Modal.Body>
                    <VStack alignItems={"center"} mt={2}></VStack>

                    {modalData?.columns?.map(({ column_name, assigned_id }) => {
                      return (
                        <VStack key={assigned_id}>
                          <Text>{column_name}</Text>
                          <Input
                            value={rowValues[column_name]}
                            onChangeText={(text) => {
                              handleRowValueChange(text, column_name);
                            }}
                            variant={"outline"}
                          ></Input>
                        </VStack>
                      );
                    })}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      flex="1"
                      onPress={() => {
                        saveNewTableEntry();
                      }}
                    >
                      Create New Entry
                    </Button>
                  </Modal.Footer>
                </Modal.Content>
              </Modal>
            </ScrollView>
          </DashboardLayout>
          <MobileFooter nav={props.navigation} />
        </>
      )}
    </Observer>
  );
}
