// import "react-native-gesture-handler";
import React from "react";
import { createDrawerNavigator } from "@react-navigation/drawer";
// import { Linking, Platform } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { NavigationContainer } from "@react-navigation/native";
import { NativeBaseProvider, extendTheme, theme as nbTheme } from "native-base";
import SignUp from "./screens/SignUp";
import FinishSignUp from "./screens/FinishSignUp";
import SignIn from "./screens/SignIn";
import SetupCode from "./screens/SetupCode";
import ResetPasswordScreen from "./screens/ResetPassword";
import OnCallScreen from "./screens/OnCallScreen";
import Splash from "./screens/Splash";
import HomeScreen from "./screens/HomeScreen";
import GhostCallScreen from "./screens/GhostCallScreen";
import CallRecordingsScreen from "./screens/CallRecordingsScreen";
import { StoreProvider } from "./utilities/store";
import DataTablesScreen from "./screens/DataTablesScreen";
import SettingsScreen from "./screens/SettingsScreen";
import AllMessagesScreen from "./screens/AllMessagesScreen";
import NotifCustomizeScreen from "./screens/NotifCustomizeScreen";
import OneSignal from "react-native-onesignal";

// OneSignal Initialization
OneSignal.setAppId("8015d190-3a32-410d-9b1d-c687fa65708d");

// promptForPushNotificationsWithUserResponse will show the native iOS or Android notification permission prompt.
// We recommend removing the following code and instead using an In-App Message to prompt for notification permission (See step 8)

//Method for handling  received while app in foreground
OneSignal.setNotificationWillShowInForegroundHandler(
  (notificationReceivedEvent) => {
    console.log(
      "OneSignal: notification will show in foreground:",
      notificationReceivedEvent
    );
    let notification = notificationReceivedEvent.getNotification();
    console.log("notification: ", notification);
    const data = notification.additionalData;
    console.log("additionalData: ", data);
    // Complete with null means don't show a notification.
    notificationReceivedEvent.complete(notification);
  }
);

//Method for handling notifications opened
OneSignal.setNotificationOpenedHandler((notification) => {
  console.log("OneSignal: notification opened:", notification);
});

const theme = extendTheme({
  colors: {
    primary: nbTheme.colors.emerald,
  },
});

const Drawer = createDrawerNavigator();

export default function App() {
  /*nav persistance*/
  const PERSISTENCE_KEY = "NAVIGATION_STATE_V1";
  const [isReady, setIsReady] = React.useState(true); //change to false to persist nav state //set to true when shipping
  const [initialState, setInitialState] = React.useState();

  React.useEffect(() => {
    const restoreState = async () => {
      try {
        const savedStateString = await AsyncStorage.getItem(PERSISTENCE_KEY);
        const state = savedStateString
          ? JSON.parse(savedStateString)
          : undefined;
        if (state !== undefined) {
          setInitialState(state);
        } else {
        }
      } finally {
        setIsReady(true);
      }
    };

    if (!isReady) {
      restoreState();
    }
  }, [isReady]);

  if (!isReady) {
    return null;
  }
  /* end nav persistance*/
  return (
    <StoreProvider>
      <NativeBaseProvider theme={theme}>
        <NavigationContainer
          initialState={initialState}
          onStateChange={(state) =>
            AsyncStorage.setItem(PERSISTENCE_KEY, JSON.stringify(state))
          }
        >
          <Drawer.Navigator
            screenOptions={{ headerShown: false }}
            useLegacyImplementation={true}
          >
            <Drawer.Screen
              name={"Splash"}
              component={Splash}
              options={{ title: "BlazeRelay" }}
            />
            <Drawer.Screen
              name={"SignIn"}
              component={SignIn}
              options={{ title: "BlazeRelay - Sign In" }}
            />
            <Drawer.Screen
              name={"SignUp"}
              component={SignUp}
              options={{ title: "BlazeRelay - Sign Up" }}
            />
            <Drawer.Screen
              name={"FinishSignUp"}
              component={FinishSignUp}
              options={{ title: "BlazeRelay - Sign Up" }}
            />
            <Drawer.Screen
              name={"SetupCode"}
              component={SetupCode}
              options={{ title: "BlazeRelay - Sign Up" }}
            />
            <Drawer.Screen
              name={"ResetPasswordScreen"}
              component={ResetPasswordScreen}
              options={{ title: "BlazeRelay - Reset Password" }}
            />
            <Drawer.Screen
              name={"HomeScreen"}
              component={HomeScreen}
              options={{ title: "BlazeRelay - Home" }}
            />
            <Drawer.Screen
              name={"AllMessagesScreen"}
              component={AllMessagesScreen}
              options={{ title: "BlazeRelay - All Messages" }}
            />
            <Drawer.Screen
              name={"OnCallScreen"}
              component={OnCallScreen}
              options={{ title: "BlazeRelay - On Call" }}
            />
            <Drawer.Screen
              name={"GhostCallScreen"}
              component={GhostCallScreen}
              options={{ title: "BlazeRelay - Ghost Call" }}
            />
            <Drawer.Screen
              name={"CallRecordingsScreen"}
              component={CallRecordingsScreen}
              options={{ title: "BlazeRelay - Call Recordings" }}
            />
            <Drawer.Screen
              name={"DataTablesScreen"}
              component={DataTablesScreen}
              options={{ title: "BlazeRelay - Data Tables" }}
            />
            <Drawer.Screen
              name={"SettingsScreen"}
              component={SettingsScreen}
              options={{ title: "BlazeRelay - Settings" }}
            />
            <Drawer.Screen
              name={"NotifCustomizeScreen"}
              component={NotifCustomizeScreen}
              options={{ title: "BlazeRelay - Notification Preferences" }}
            />
          </Drawer.Navigator>
        </NavigationContainer>
      </NativeBaseProvider>
    </StoreProvider>
  );
}
