import React, { useState } from "react";

import { Platform } from "react-native";
import {
  Button,
  HStack,
  VStack,
  Text,
  Link,
  Checkbox,
  Switch,
  Divider,
  Image,
  useColorModeValue,
  IconButton,
  Icon,
  Pressable,
  Center,
  Hidden,
  StatusBar,
  Stack,
  Box,
} from "native-base";
import { AntDesign, Entypo } from "@expo/vector-icons";
import FloatingLabelInput from "./components/FloatingLabelInput";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import {
  authLogin,
  fetchAPIJSON,
  reportLoginFlowDebug,
} from "../../utilities/network";
import { StoreContext } from "../../utilities/store";
import { useToast } from "native-base";
import * as Keychain from "react-native-keychain";
import OneSignal from "react-native-onesignal";

export function SignInForm({ props }) {
  // const router = useRouter(); //use incase of Nextjs
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [showPass, setShowPass] = React.useState(false);
  const [saveDetails, setSaveDetails] = React.useState(false);

  const store = React.useContext(StoreContext);
  const toast = useToast();

  React.useEffect(() => {
    const unsubscribe = props.navigation.addListener("focus", async () => {
      try {
        const creds = await Keychain.getGenericPassword({
          accessControl: Keychain.ACCESS_CONTROL.BIOMETRY_ANY,
          authenticationPrompt: { title: "Use Biometrics to Login" },
        });
        if (creds && creds.username != null) {
          console.log(
            "Credentials successfully loaded for user " + creds.username
          );
          authSignIn(creds.username, creds.password);
        } else {
          console.log("No credentials stored");
        }
      } catch (error) {
        console.log("Keychain couldn't be accessed!", error);
      }
    });

    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe;
  }, [props.navigation]);

  const authSignIn = (email_in, pass_in) => {
    console.log(email_in.toLowerCase(), pass_in);
    console.log("0");
    authLogin(email_in.toLowerCase(), pass_in)
      .then(async (authRes) => {
        console.log("1");
        store.accessToken = authRes.access_token;
        store.refreshToken = authRes.refresh_token;
        store.user_id = authRes.user_id;
        store.userFirstName = authRes.first_name;
        store.userLastName = authRes.last_name;
        store.userEmail = authRes.email;
        store.userPermissions = {
          access_on_call: authRes.userPermissions.access_on_call,
          access_all_messages: authRes.userPermissions.access_all_messages,
          access_ghost_call: authRes.userPermissions.access_ghost_call,
          access_call_recordings:
            authRes.userPermissions.access_call_recordings,
          access_datatables: authRes.userPermissions.access_datatables,
        };
        store.sip_id = authRes.sip_id;
        store.sip_name = authRes.sip_name;
        store.sip_username = authRes.sip_username;
        store.sip_password = authRes.sip_password;
        store.sip_from_number = authRes.sip_from_number;
        store.accs_array = authRes.userPermissions.accs;

        console.log("2");

        if (saveDetails == true) {
          console.log("setting login details");
          try {
            await Keychain.setGenericPassword(email, pass, {
              accessControl: Keychain.ACCESS_CONTROL.BIOMETRY_ANY,
              authenticationPrompt: { title: "Use Biometrics to Login" },
              storage: Keychain.STORAGE_TYPE.RSA,
            });
          } catch (e) {
            // report this here
            console.log(e);
          }
        }

        if (Platform.OS == "web") {
          props.navigation.navigate("HomeScreen");
        } else {
          console.log("3");

          OneSignal.setExternalUserId(authRes.user_id);
          OneSignal.setEmail(authRes.email);

          console.log("4");

          OneSignal.promptForPushNotificationsWithUserResponse((response) => {
            console.log("5");
            console.log("Prompt response:", response);
            props.navigation.navigate("HomeScreen");
          });
        }
      })
      .catch((err) => {
        console.log("sign in err", err);
        reportLoginFlowDebug(false, "login-1", {
          eInfo: err,
          email: email_in,
        });
        toast.show({
          description: "Invalid Login Details",
          placement: "top",
        });
      });
  };

  // const registerForPushNotificationsAsync = async () => {
  //   // if (Device.isDevice && Platform.OS != "web") {
  //   if (Platform.OS != "web") {
  //     const { status: existingStatus } =
  //       await Notifications.getPermissionsAsync();
  //     let finalStatus = existingStatus;
  //     if (existingStatus !== "granted") {
  //       console.log("requesting permissions");
  //       const { status } = await Notifications.requestPermissionsAsync();
  //       console.log(await Notifications.requestPermissionsAsync());
  //       finalStatus = status;
  //     }
  //     if (finalStatus === "denied") {
  //       alert(
  //         "Failed to enable push notifications. Please check to ensure you've allowed push notifications in app settings."
  //       );
  //       console.log("Final Status", finalStatus);
  //       return;
  //     }
  //     const token = (
  //       await Notifications.getExpoPushTokenAsync({
  //         experienceId: "@rt-ringflare/BlazeRelay",
  //       })
  //     ).data;
  //     console.log("token: \n", token, "\n\n");
  //     console.log("@rt-ringflare/BlazeRelay");
  //     // this.setState({ expoPushToken: token });
  //     fetchAPIJSON("api/v1/blaze/setPushToken", store, props.navigation, {
  //       pushToken: token,
  //     });
  //   } else {
  //     console.log("Must use physical device for Push Notifications");
  //   }

  //   if (Platform.OS === "android") {
  //     Notifications.setNotificationChannelAsync("default", {
  //       name: "default",
  //       importance: Notifications.AndroidImportance.MAX,
  //       vibrationPattern: [0, 250, 250, 250],
  //       lightColor: "#FF231F7C",
  //     });
  //   }
  // };

  return (
    <KeyboardAwareScrollView
      contentContainerStyle={{
        flexGrow: 1,
      }}
      style={{
        flex: 1,
      }}
    >
      <VStack
        flex="1"
        px="6"
        py="9"
        _light={{
          bg: "white",
        }}
        _dark={{
          bg: "coolGray.800",
        }}
        space="3"
        justifyContent="space-between"
        borderTopRightRadius={{
          base: "2xl",
          md: "xl",
        }}
        borderBottomRightRadius={{
          base: "0",
          md: "xl",
        }}
        borderTopLeftRadius={{
          base: "2xl",
          md: "0",
        }}
      >
        <VStack space="7">
          <Hidden till="md">
            <Text fontSize="lg" fontWeight="normal">
              Sign In
            </Text>
          </Hidden>
          <VStack>
            <VStack space="3">
              <VStack
                space={{
                  base: "7",
                  md: "4",
                }}
              >
                <FloatingLabelInput
                  isRequired
                  label="Email"
                  labelColor="#9ca3af"
                  labelBGColor={useColorModeValue("#fff", "#1f2937")}
                  borderRadius="4"
                  defaultValue={email}
                  onChangeText={(txt) => setEmail(txt)}
                  _text={{
                    fontSize: "sm",
                    fontWeight: "medium",
                  }}
                  _dark={{
                    borderColor: "coolGray.700",
                  }}
                  _light={{
                    borderColor: "coolGray.300",
                  }}
                />
                <FloatingLabelInput
                  isRequired
                  type={showPass ? "" : "password"}
                  label="Password"
                  borderRadius="4"
                  labelColor="#9ca3af"
                  labelBGColor={useColorModeValue("#fff", "#1f2937")}
                  defaultValue={pass}
                  onChangeText={(txt) => setPass(txt)}
                  InputRightElement={
                    <IconButton
                      variant="unstyled"
                      icon={
                        <Icon
                          size="4"
                          color="coolGray.400"
                          as={Entypo}
                          name={showPass ? "eye-with-line" : "eye"}
                        />
                      }
                      onPress={() => {
                        setShowPass(!showPass);
                      }}
                    />
                  }
                  _text={{
                    fontSize: "sm",
                    fontWeight: "medium",
                  }}
                  _dark={{
                    borderColor: "coolGray.700",
                  }}
                  _light={{
                    borderColor: "coolGray.300",
                  }}
                />
              </VStack>
              <Link
                ml="auto"
                _text={{
                  fontSize: "xs",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
                _light={{
                  _text: {
                    color: "primary.900",
                  },
                }}
                _dark={{
                  _text: {
                    color: "primary.500",
                  },
                }}
              >
                {/* Forgot password? */}
              </Link>
              {Platform.OS != "web" && (
                <HStack alignItems="center" space={4}>
                  <Switch
                    size="md"
                    onToggle={(e) => {
                      setSaveDetails(e);
                    }}
                    isChecked={saveDetails}
                  />
                  <Text>Save Login Details</Text>
                </HStack>
              )}

              <Button
                mt="5"
                size="md"
                borderRadius="4"
                _text={{
                  fontWeight: "medium",
                }}
                _light={{
                  bg: "primary.900",
                }}
                _dark={{
                  bg: "primary.700",
                }}
                onPress={() => {
                  authSignIn(email, pass);
                }}
              >
                SIGN IN
              </Button>
              <HStack
                mt="5"
                space="2"
                mb={{
                  base: 6,
                  md: 7,
                }}
                alignItems="center"
                justifyContent="center"
              ></HStack>
            </VStack>
          </VStack>
        </VStack>
      </VStack>
    </KeyboardAwareScrollView>
  );
}
export default function SignIn(props) {
  return (
    <>
      <StatusBar
        translucent
        backgroundColor="transparent"
        barStyle="light-content"
      />
      <Box
        safeAreaTop
        _light={{
          bg: "primary.900",
        }}
        _dark={{
          bg: "coolGray.900",
        }}
      />
      <Center
        my="auto"
        _dark={{
          bg: "coolGray.900",
        }}
        _light={{
          bg: "primary.900",
        }}
        flex="1"
        p={{ md: 8 }}
      >
        <Stack
          flexDirection={{
            base: "column",
            md: "row",
          }}
          w="100%"
          maxW={{
            md: "1016px",
          }}
          flex={{
            base: "1",
            md: "none",
          }}
        >
          <Hidden from="md">
            <VStack px="4" mt="4" mb="5" space="9">
              <HStack space="2" alignItems="center">
                <IconButton
                  variant="unstyled"
                  pl="0"
                  onPress={() => {
                    props.navigation.goBack();
                  }}
                  icon={
                    <Icon
                      size="6"
                      as={AntDesign}
                      name="arrowleft"
                      color="coolGray.50"
                    />
                  }
                />
                <Text color="coolGray.50" fontSize="lg">
                  Sign In
                </Text>
              </HStack>
              <VStack space="2">
                <Text fontSize="3xl" fontWeight="bold" color="coolGray.50">
                  Welcome back!
                </Text>
                <Text
                  fontSize="md"
                  fontWeight="normal"
                  _dark={{
                    color: "coolGray.400",
                  }}
                  _light={{
                    color: "primary.300",
                  }}
                >
                  Sign in to continue
                </Text>
              </VStack>
            </VStack>
          </Hidden>
          <Hidden till="md">
            <Center
              flex="1"
              bg="primary.700"
              borderTopLeftRadius={{
                base: "0",
                md: "xl",
              }}
              borderBottomLeftRadius={{
                base: "0",
                md: "xl",
              }}
            >
              <Image
                h="24"
                size="80"
                alt="Blaze Relay Logo"
                resizeMode={"contain"}
                source={require("../../assets/BlazeRelayLogo.png")}
              />
            </Center>
          </Hidden>
          <SignInForm props={props} />
        </Stack>
      </Center>
    </>
  );
}
