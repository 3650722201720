import React from "react";
import {
  Box,
  VStack,
  StatusBar,
  ScrollView,
  HStack,
  Pressable,
  Icon,
  Image,
  Text,
  Hidden,
  useColorMode,
  IconButton,
  Divider,
  Menu,
  Avatar,
  Input,
} from "native-base";

import {
  AntDesign,
  FontAwesome,
  Ionicons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";

import Sidebar from "../components/Sidebar";

import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

export function Header(props) {
  const { colorMode } = useColorMode();
  return (
    <Box
      px="6"
      pt="3"
      pb="3"
      borderBottomWidth="1"
      _dark={{ bg: "coolGray.900", borderColor: "coolGray.600" }}
      _light={{
        bg: { base: "white", md: "primary.700" },
        borderColor: "coolGray.200",
      }}
    >
      <VStack
        alignSelf="center"
        width="100%"
        maxW={props.menuButton ? null : "1016px"}
      >
        <HStack alignItems="center" justifyContent="space-between">
          <HStack alignItems="center">
            {/* {props.menuButton && (
              <IconButton
                variant="ghost"
                colorScheme="light"
                onPress={props.toggleSidebar}
                icon={
                  <Icon
                    size="6"
                    name="menu-sharp"
                    as={Ionicons}
                    _light={{ color: "white" }}
                    _dark={{ color: "coolGray.50" }}
                  />
                }
              />
            )} */}

            {colorMode === "light" ? (
              <Image
                h="75"
                w="225"
                alt="Blaze Relay Logo"
                resizeMode="contain"
                source={require("../assets/BlazeRelayLogo.png")}
              />
            ) : (
              <Image
                h="75"
                w="225"
                alt="Blaze Relay Logo"
                resizeMode="contain"
                source={require("../assets/BlazeRelayLogo.png")}
              />
            )}
          </HStack>

          <HStack space="2" alignItems="center">
            {/* <IconButton
              variant="ghost"
              colorScheme="light"
              icon={
                <Icon
                  size="6"
                  name="bell"
                  as={FontAwesome}
                  _dark={{
                    color: "coolGray.200",
                  }}
                  _light={{
                    color: "coolGray.400",
                  }}
                />
              }
            /> */}
            {/* <Menu
              closeOnSelect={false}
              w="200"
              placement="bottom right"
              onOpen={() => console.log("opened")}
              onClose={() => console.log("closed")}
              trigger={(triggerProps) => {
                return (
                  <IconButton
                    {...triggerProps}
                    variant="ghost"
                    colorScheme="light"
                    icon={
                      <Avatar
                        w="8"
                        h="8"
                        _dark={{ bg: "coolGray.200" }}
                        source={require("../assets/women.jpg")}
                      />
                    }
                  />
                );
              }}
              //@ts-ignore
              _dark={{ bg: "coolGray.800", borderColor: "coolGray.700" }}
            >
              <Menu.Group title="Profile">
                <Menu.Item>Account</Menu.Item>
              </Menu.Group>
              <Divider mt="3" w="100%" _dark={{ bg: "coolGray.700" }} />
              <Menu.Group title="Shortcuts">
                <Menu.Item>Settings</Menu.Item>
                <Menu.Item>Logout</Menu.Item>
              </Menu.Group>
            </Menu> */}
          </HStack>
        </HStack>
      </VStack>
    </Box>
  );
}

function MainContent(props) {
  return (
    <VStack maxW="1016px" flex={1} width="100%">
      {props.displayScreenTitle && (
        <Hidden till="md">
          <HStack mb="4" space={2} alignItems="center">
            <Pressable>
              <Icon
                size="6"
                as={AntDesign}
                name={"arrowleft"}
                _light={{ color: "coolGray.800" }}
                _dark={{ color: "coolGray.50" }}
              />
            </Pressable>
            <Text
              fontSize="lg"
              _dark={{ color: "coolGray.50" }}
              _light={{ color: "coolGray.800" }}
            >
              {props.title}
            </Text>
          </HStack>
        </Hidden>
      )}
      {props.children}
    </VStack>
  );
}

export function MobileHeader(props) {
  return (
    <Box
      _dark={{ bg: "coolGray.900", borderColor: "coolGray.800" }}
      _light={{
        bg: { base: "pink", md: "pink" },
        borderColor: "coolGray.200",
      }}
    >
      {/* <HStack space="2" justifyContent="space-between">
        <HStack
          flex="1"
          space="2"
          justifyContent="space-between"
          alignItems="center"
        >
          <>
            <HStack alignItems="center" space="1">
              {props.backButton && (
                <IconButton
                  variant="ghost"
                  colorScheme="light"
                  icon={
                    <Icon
                      size="6"
                      as={AntDesign}
                      name="arrowleft"
                      color="coolGray.50"
                    />
                  }
                />
              )}

              <Text color="coolGray.50" fontSize="lg">
                {props.title}
              </Text>
            </HStack>
            <HStack space="1">
              <IconButton
                variant="ghost"
                colorScheme="light"
                icon={
                  <Icon
                    size="6"
                    name="bell"
                    as={FontAwesome}
                    _dark={{
                      color: "coolGray.200",
                    }}
                    _light={{
                      color: "coolGray.50",
                    }}
                  />
                }
              />
              <Menu
                w="150"
                trigger={(triggerProps) => {
                  return (
                    <IconButton
                      variant="ghost"
                      colorScheme="light"
                      accessibilityLabel="More options menu"
                      {...triggerProps}
                      icon={
                        <Icon
                          size="6"
                          color="coolGray.50"
                          name={"dots-vertical"}
                          as={MaterialCommunityIcons}
                        />
                      }
                    />
                  );
                }}
                placement="bottom right"
                //@ts-ignore
                _dark={{ bg: "coolGray.800", borderColor: "coolGray.700" }}
              >
                <Menu.Item>New Group</Menu.Item>
                <Menu.Item>New Broadcast</Menu.Item>
                <Menu.Item>Settings</Menu.Item>
              </Menu>
            </HStack>
          </>
        </HStack>
      </HStack> */}
    </Box>
  );
}

export default function DashboardLayout({
  // scrollable = true,
  displayScreenTitle = true,
  displaySidebar = true,
  header = {
    searchbar: false,
  },
  mobileHeader = {
    backButton: true,
  },
  ...props
}) {
  const [isSidebarVisible, setIsSidebarVisible] = React.useState(true);
  function toggleSidebar() {
    setIsSidebarVisible(!isSidebarVisible);
  }

  return (
    <>
      <StatusBar barStyle="dark-content" />
      <Box
        safeAreaTop
        _light={{ bg: "warmGray.50" }}
        _dark={{ bg: "coolGray.900" }}
      />
      <VStack flex={1} bgColor={"warmGray.50"}>
        <KeyboardAwareScrollView
          contentContainerStyle={{ width: "100%", height: "100%" }}
        >
          <Hidden from="md">
            <MobileHeader
              title={props.title}
              backButton={mobileHeader.backButton}
            />
          </Hidden>
          <Hidden till="md">
            <Header
              toggleSidebar={toggleSidebar}
              title={props.title}
              menuButton={displaySidebar}
              searchbar={header.searchbar}
            />
          </Hidden>

          <Box
            flex={1}
            // safeAreaBottom
            flexDirection={{ base: "column", md: "row" }}
            borderTopColor={"coolGray.200"}
          >
            {isSidebarVisible && displaySidebar && (
              <Hidden till="md">
                <Sidebar nav={props.nav} />
              </Hidden>
            )}

            <Hidden till="md">
              <ScrollView
                flex={1}
                p={{ md: 8 }}
                contentContainerStyle={{ alignItems: "center", flexGrow: 1 }}
              >
                <MainContent
                  {...props}
                  displayScreenTitle={displayScreenTitle}
                />
              </ScrollView>
            </Hidden>

            <Hidden from="md">
              <MainContent {...props} displayScreenTitle={displayScreenTitle} />
            </Hidden>
          </Box>
        </KeyboardAwareScrollView>
      </VStack>
    </>
  );
}
