import { useLocalStore } from "mobx-react";
import { action, configure } from "mobx";
import { makePersistable } from "mobx-persist-store";
import * as Keychain from "react-native-keychain";
import AsyncStorage from "@react-native-async-storage/async-storage";
import React from "react";
import { Platform } from "react-native";

const StoreContext = React.createContext();

const StoreProvider = ({ children }) => {
  configure({ enforceActions: "never" });
  const store = useLocalStore(() => ({
    saveLoginDetails: "",
    setupOrgId: "",
    setupOrgName: "",
    setupCode: "",
    accessToken: "",
    refreshToken: "",
    user_id: "",
    userFirstName: "",
    userLastName: "",
    userEmail: "",
    sip_id: "",
    sip_name: "",
    sip_username: "",
    sip_password: "",
    sip_from_number: "",
    sip_current_session: null,
    ghostCallCurrentUUID: null,
    ghostCallToNumber: "",
    accs_array: [],
    userPermissions: {
      access_on_call: false,
      access_all_messages: false,
      access_ghost_call: false,
      access_call_recordings: false,
      access_datatables: false,
    },

    get authHeader() {
      return `${store.accessToken},${store.refreshToken}`;
    },
    get accessOnCall() {
      return store.userPermissions.access_on_call;
    },
    get accessGhostCall() {
      return store.userPermissions.access_ghost_call;
    },
    get accessCallRecordings() {
      return store.userPermissions.access_call_recordings;
    },
    get accessDatatables() {
      return store.userPermissions.access_datatables;
    },
    get accessAllMessages() {
      return store.userPermissions.access_all_messages;
    },
    clear() {
      this.setupOrgId = "";
      this.setupOrgName = "";
      this.setupCode = "";
      this.accessToken = "";
      this.refreshToken = "";
      this.user_id = "";
      this.userFirstName = "";
      this.userLastName = "";
      this.userEmail = "";
      this.sip_id = "";
      this.sip_name = "";
      this.sip_username = "";
      this.sip_password = "";
      this.sip_from_number = "";
      this.sip_current_session = null;
      this.ghostCallCurrentUUID = null;
      this.ghostCallToNumber = "";
      this.accs_array = [];
      this.userPermissions = {
        access_on_call: false,
        access_all_messages: false,
        access_ghost_call: false,
        access_call_recordings: false,
        access_datatables: false,
      };
      console.log("Clearing Store.");
    },
    signOut() {
      // if mobile dont do this next line
      if (Platform.OS != "web") {
        Keychain.resetGenericPassword();
      }
      // console.log(Platform.isTesting)
      this.clear();
      console.log("Signing Out.");
    },

    // bugs: ["Centipede"],
    // addBug: (bug) => {
    //   store.bugs.push(bug);
    // },
    // get bugsCount() {
    //   return store.bugs.length;
    // },
  }));

  makePersistable(
    store,
    {
      name: "GeneralStore",
      properties: [
        "saveLoginDetails",
        "accessToken",
        "refreshToken",
        "user_id",
        "userFirstName",
        "userLastName",
        "userEmail",
        "setupCode",
        "setupOrgId",
        "setupOrgName",
        "sip_id",
        "sip_name",
        "sip_username",
        "sip_password",
        "sip_from_number",
        "sip_current_session",
        "ghostCallCurrentUUID",
        "ghostCallToNumber",
        "accs_array",
        "userPermissions",
      ],
      storage: AsyncStorage,
      debugMode: true,
    },
    { fireImmediately: true }
  ).then(
    action((persistStore) => {
      console.log("HYDRO", persistStore.isHydrated);
    })
  );

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export { StoreProvider, StoreContext };
