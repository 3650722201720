import React, { useState } from "react";
import {
  VStack,
  Box,
  HStack,
  Icon,
  Text,
  Link,
  Button,
  Image,
  Hidden,
  IconButton,
  Center,
  FormControl,
  StatusBar,
  Stack,
  Input,
} from "native-base";
import { AntDesign } from "@expo/vector-icons";
import { resetPassword } from "../../utilities/network";
import { StoreContext } from "../../utilities/store";
import { useToast } from "native-base";

export default function ResetPasswordScreen(props) {
  const store = React.useContext(StoreContext);
  // const router = useRouter(); //use incase of Nextjs
  const [userEmail, setUserEmail] = useState("");
  const [hasResetPassword, setHasResetPassword] = useState(false);

  const toast = useToast();

  React.useEffect(() => {
    const unsubscribe = props.navigation.addListener("focus", () => {
      setUserEmail("");
      setHasResetPassword(false);
    });

    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe;
  }, [props.navigation]);

  const onClickResetPass = () => {
    setHasResetPassword(true);
    resetPassword(userEmail)
      .then((res) => {
        console.log("good", res);

        toast.show({
          description: "Password Reset Request Sent",
          placement: "top",
        });
      })
      .catch((err) => {
        toast.show({
          placement: "top",
          render: () => {
            return (
              <Box bg="red.500" px="2" py="1" rounded="sm" mb={5}>
                Failed to reset password.
              </Box>
            );
          },
        });
        console.log("bad", err);
      });
  };
  return (
    <>
      <StatusBar
        translucent
        backgroundColor="transparent"
        barStyle="light-content"
      />
      <Box
        safeAreaTop
        _light={{
          bg: "primary.900",
        }}
        _dark={{
          bg: "coolGray.900",
        }}
      />
      <Center
        my="auto"
        _dark={{
          bg: "coolGray.900",
        }}
        _light={{
          bg: "primary.900",
        }}
        flex="1"
        p={{ md: 8 }}
      >
        <Stack
          flexDirection={{
            base: "column",
            md: "row",
          }}
          w="100%"
          maxW={{
            md: "1016px",
          }}
          flex={{
            base: "1",
            md: "none",
          }}
        >
          <Hidden from="md">
            <HStack space="2" px="4" mt="4" mb="5" alignItems="center">
              <IconButton
                variant="unstyled"
                onPress={() => {
                  props.navigation.goBack();
                }}
                icon={
                  <Icon
                    alignItems="center"
                    justifyContent="center"
                    size="6"
                    as={AntDesign}
                    name="arrowleft"
                    color="coolGray.50"
                  />
                }
              />
              <Text color="coolGray.50" fontSize="lg">
                Setup
              </Text>
            </HStack>
          </Hidden>
          <Hidden till="md">
            <Center
              flex="1"
              bg="primary.700"
              px={{
                base: "4",
                md: "8",
              }}
              borderTopLeftRadius={{
                md: "xl",
              }}
              borderBottomLeftRadius={{
                md: "xl",
              }}
            >
              <Image
                h="24"
                size="80"
                alt="BlazeRelay Logo"
                resizeMode={"contain"}
                source={require("../../assets/BlazeRelayLogo.png")}
              />
            </Center>
          </Hidden>
          <Box
            py={{
              base: "6",
              md: "12",
            }}
            px={{
              base: "4",
              md: "10",
            }}
            _light={{
              bg: "white",
            }}
            _dark={{
              bg: "coolGray.800",
            }}
            flex="1"
            borderTopRightRadius={{
              md: "xl",
            }}
            borderBottomRightRadius={{
              md: "xl",
            }}
          >
            <VStack justifyContent="space-between" flex="1" space="24">
              <Box>
                <VStack
                  space={{
                    base: "4",
                    md: "5",
                  }}
                >
                  <Text
                    fontSize="xl"
                    fontWeight="bold"
                    _dark={{
                      color: "coolGray.50",
                    }}
                    _light={{
                      color: "coolGray.800",
                    }}
                  >
                    Reset Password
                  </Text>
                  <HStack space="2" alignItems="center">
                    <Text
                      _light={{
                        color: "coolGray.800",
                      }}
                      _dark={{
                        color: "coolGray.400",
                      }}
                    >
                      Enter the email address associated with your account
                      below. We'll email you a new setup code.
                    </Text>
                  </HStack>
                </VStack>
                <VStack space="12" mt="6">
                  <FormControl>
                    <Input
                      // maxLength={6}
                      fontSize={"3xl"}
                      mx="3"
                      placeholder="john.doe@blazerelay.com"
                      value={userEmail}
                      isDisabled={hasResetPassword}
                      onChangeText={(txt) => {
                        setUserEmail(txt);
                      }}
                      // width={"100%"}
                      variant="underlined"
                    />
                    <FormControl.HelperText mt="7"></FormControl.HelperText>
                  </FormControl>
                  {!hasResetPassword && (
                    <Button
                      size="md"
                      _light={{
                        bg: "primary.900",
                      }}
                      _dark={{
                        bg: "primary.700",
                      }}
                      onPress={() => {
                        onClickResetPass();
                      }}
                    >
                      Send New Setup Code
                    </Button>
                  )}
                  {hasResetPassword && (
                    <VStack>
                      <Text fontSize={"2xl"}>
                        If {userEmail} matches an account, we will email a new
                        setup code to that email address.
                      </Text>

                      <Button
                        size="md"
                        _light={{
                          bg: "primary.900",
                        }}
                        _dark={{
                          bg: "primary.700",
                        }}
                        onPress={() => {
                          props.navigation.navigate("SetupCode");
                        }}
                        mt={"16"}
                      >
                        Enter Setup Code
                      </Button>
                    </VStack>
                  )}
                </VStack>
              </Box>
              <HStack
                mt="28"
                mb="4"
                space="1"
                safeAreaBottom
                alignItems="center"
                justifyContent="center"
              >
                <Text
                  _light={{
                    color: "coolGray.800",
                  }}
                  _dark={{
                    color: "coolGray.400",
                  }}
                >
                  Don't need a password reset?
                </Text>

                <Link
                  _text={{
                    fontWeight: "bold",
                    textDecoration: "none",
                  }}
                  _light={{
                    _text: {
                      color: "primary.900",
                    },
                  }}
                  _dark={{
                    _text: {
                      color: "violet.500",
                    },
                  }}
                  onPress={() => {
                    props.navigation.navigate("SignIn");
                  }}
                >
                  Sign In
                </Link>
                {/* Closing Link Tag */}
              </HStack>
            </VStack>
          </Box>
        </Stack>
      </Center>
    </>
  );
}
